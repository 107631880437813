import { Button, Input, InputNumber, Layout, Modal, Select, Space } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "src/core/components/Spinner";
import { categoryService } from "src/core/services/categoryService";
import { textColors } from "src/core/style/colors";
import { Category, Menu } from "src/types/types";

type Props = {
  categoryProps?: Category;
  activeState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  onSuccess?: () => void;
  userMenus?: Menu[];
};

const { Option } = Select;
const { TextArea } = Input;

export const CategoryEditModal = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [isCurrentParent, setIsCurrentParent] = useState(false);

  const [menuCategories, setMenuCategories] = useState<Category[]>();
  const [category, setCategory] = useState<Category>({
    title: "",
    description: "",
    id: 0,
    orderNumber: 0,
    twoColumns: false,
    bottomNote: "",
    hideHeader: false,
    hidden: false,
    orderByPrice: false,
  });

  const [categoryMenu, setCategoryMenu] = useState<Menu | undefined>();

  const [submit, setSubmit] = useState(false);

  const { t } = useTranslation();

  const {
    activeState: [active, setActive],
    userMenus,
  } = props;

  useEffect(() => {
    if (userMenus && category) {
      const categoryMenu = userMenus.find((c) => c.id === category.menuId);

      setCategoryMenu(categoryMenu);
    }
  }, [category, userMenus]);

  useEffect(() => {
    if (props.categoryProps) {
      setCategory(props.categoryProps);
    }
  }, [props.categoryProps]);

  useEffect(() => {
    (async () => {
      const response = await categoryService.fetchUserCategories();

      const isParent = response.find(
        (c) => c.parentId === props.categoryProps?.id
      );

      setIsCurrentParent(isParent !== undefined);

      const currentMenuCategories = response.filter(
        (c) => c.menuId === categoryMenu?.id
      );

      setMenuCategories(currentMenuCategories);

      setLoading(false);
    })();
  }, [categoryMenu?.id, props.categoryProps?.id]);

  const save = async () => {
    setSubmit(true);
    await categoryService.updateCategory(category);
    setSubmit(false);

    if (props.onSuccess) {
      props.onSuccess();
    }
  };

  return (
    <Modal
      open={active}
      title={t("modal.title.menuCategory")}
      footer={null}
      onCancel={() => {
        setActive(false);
      }}
    >
      <Layout>
        <Content style={{ background: "white" }}>
          <Input
            autoFocus
            placeholder={t("input.title")}
            value={category.title}
            onChange={(evt) => {
              setCategory({ ...category, title: evt.target.value });
            }}
            style={{ marginBottom: 20 }}
            required
          />
          <TextArea
            placeholder={t("input.descriptionOptional")}
            autoSize={{ minRows: 3, maxRows: 6 }}
            value={category.description}
            onChange={(evt) => {
              setCategory({ ...category, description: evt.target.value });
            }}
            style={{ marginBottom: 20 }}
          />
          {categoryMenu && (
            <Input
              disabled
              placeholder={t("input.categoryMenu")}
              value={`${t("input.categoryMenu")}: ${categoryMenu.title} (${
                categoryMenu.description
              })`}
              style={{ marginBottom: 20 }}
              title={`${t("input.categoryMenu")}: ${categoryMenu.title} (${
                categoryMenu.description
              })`}
            />
          )}
          {!isCurrentParent && (
            <Select
              showSearch
              filterOption={(input, option) => {
                if (!Array.isArray(option?.children)) {
                  return (
                    (option?.children ?? "")
                      ?.toLowerCase()
                      .indexOf(input?.toLowerCase()) >= 0
                  );
                } else {
                  return false;
                }
              }}
              loading={loading}
              value={category.parentId === 0 ? undefined : category.parentId}
              placeholder={t("input.parentCategory")}
              style={{ width: "100%", marginBottom: 20 }}
              onChange={(value: any) => {
                setCategory({ ...category, parentId: value });
              }}
            >
              <Option key={`c-e-empty`} value={0}>
                {t("label.noParentCategory")}
              </Option>
              {menuCategories?.map((item: Category, index: number) => {
                if (item.parentId === 0 && item.id !== category.id) {
                  return (
                    <Option key={`c-e-${index}`} value={item?.id || 0}>
                      {item?.title}
                    </Option>
                  );
                } else return undefined;
              })}
            </Select>
          )}
          <Space>
            <p style={{ marginBottom: 20 }}>
              <b>{t("input.orderNumber")}:</b>
            </p>
            <InputNumber
              placeholder={t("input.orderNumber")}
              value={category.orderNumber}
              onChange={(value) => {
                setCategory({
                  ...category,
                  orderNumber: value ?? 0,
                });
              }}
              style={{ marginBottom: 20 }}
            />
          </Space>
          {!isCurrentParent && (
            <React.Fragment>
              <Space style={{ width: "100%" }}>
                <p style={{ marginBottom: 20 }}>
                  <b>{t("input.orderByPrice")}:</b>
                </p>
                <Checkbox
                  checked={category.orderByPrice}
                  onChange={(value) => {
                    setCategory({
                      ...category,
                      orderByPrice: value.target.checked,
                    });
                  }}
                  style={{ marginBottom: 20 }}
                />
              </Space>
              <Space style={{ width: "100%" }}>
                <p style={{ marginBottom: 20 }}>
                  <b>{t("input.twoColumns")}:</b>
                </p>
                <Checkbox
                  checked={category.twoColumns}
                  onChange={(value) => {
                    setCategory({
                      ...category,
                      twoColumns: value.target.checked,
                    });
                  }}
                  style={{ marginBottom: 20 }}
                />
              </Space>
              <Space style={{ width: "100%" }}>
                <p style={{ marginBottom: 20 }}>
                  <b>{t("input.hideCategory")}:</b>
                </p>
                <Checkbox
                  checked={category.hidden}
                  onChange={(value) => {
                    setCategory({
                      ...category,
                      hidden: value.target.checked,
                    });
                  }}
                  style={{ marginBottom: 20 }}
                />
              </Space>
              <Space style={{ width: "100%" }}>
                <p style={{ marginBottom: 20 }}>
                  <b>{t("input.hideHeader")}:</b>
                </p>
                <Checkbox
                  checked={category.hideHeader}
                  onChange={(value) => {
                    setCategory({
                      ...category,
                      hideHeader: value.target.checked,
                    });
                  }}
                  style={{ marginBottom: 20 }}
                />
              </Space>
              <TextArea
                placeholder={t("input.bottomNote")}
                autoSize={{ minRows: 3, maxRows: 4 }}
                value={category.bottomNote}
                onChange={(evt) => {
                  setCategory({ ...category, bottomNote: evt.target.value });
                }}
                style={{ marginBottom: 20 }}
              />
            </React.Fragment>
          )}
        </Content>
        <Content style={{ textAlign: "right", background: "white" }}>
          <Button
            type="primary"
            style={{ width: 100 }}
            onClick={() => {
              save();
            }}
            disabled={false}
          >
            {submit ? (
              <Spinner size="small" color={textColors.white} />
            ) : (
              t("button.save")
            )}
          </Button>
        </Content>
      </Layout>
    </Modal>
  );
};
