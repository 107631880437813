import { PageHeader } from "antd";

type Props = {
  pageTitle: string;
  subTitle?: string;
  buttons?: JSX.Element[];
};

export const ContentPageHeader = (props: Props) => {
  const { pageTitle, subTitle, buttons } = props;

  return (
    <PageHeader
      className="site-page-header"
      title={pageTitle}
      subTitle={subTitle}
      extra={buttons}
    ></PageHeader>
  );
};
