import { SaveTwoTone } from "@ant-design/icons";
import { Layout, Modal, Row, Select, Skeleton, Space } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "src/core/components/Spinner";
import { useViewPortQuery } from "src/core/hooks/useViewPortQuery";
import { orderService } from "src/core/services/orderService";
import { textColors } from "src/core/style/colors";
import { Order, OrderItem, OrderStatus } from "src/types/types";

type Props = {
  order?: Order;
  activeState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  onSaveSuccess?: () => void;
};

export const OrderItemsModal = (props: Props) => {
  const { order, onSaveSuccess } = props;

  const [loading, setLoading] = useState(true);
  const [orderOnSave, setOrderOnSave] = useState(false);

  const [orderItems, setOrderItems] = useState<OrderItem[]>([]);
  const [currentOrderStatus, setCurrentOrderStatus] = useState<OrderStatus>();

  const { t } = useTranslation();
  const isMobile = useViewPortQuery() === "mobile";

  const {
    activeState: [active, setActive],
  } = props;

  useEffect(() => {
    (async () => {
      if (order && order?.orderNumber) {
        setLoading(true);

        const response = await orderService.getOrderItems(
          order?.orderNumber ?? ""
        );
        setOrderItems(response);
        setCurrentOrderStatus(order?.status);

        setLoading(false);
      }
    })();
  }, [order, order?.orderNumber]);

  const onOrderSave = async () => {
    setOrderOnSave(true);

    // save order status
    await orderService.updateOrderStatus(
      order?.id ?? 0,
      currentOrderStatus ?? ""
    );

    if (onSaveSuccess) {
      onSaveSuccess();
    }

    setOrderOnSave(false);
  };

  return (
    <Modal
      open={active}
      title={
        <Space direction="vertical">
          {`${t("label.orderNumber")}: ${order?.orderNumber} ${
            order?.tableId ? " (" + order.tableId + ")" : ""
          }`}
          {order?.paymentDateTime && (
            <span style={{ fontSize: 14, fontWeight: 400 }}>
              {`${t("label.paymentDateTime")}: ${order?.paymentDateTime}`}
            </span>
          )}
          {!loading && (
            <Space>
              <Select
                defaultValue={order?.status}
                value={currentOrderStatus}
                onChange={(value: OrderStatus) => {
                  setCurrentOrderStatus(value);
                }}
                disabled={orderOnSave}
                style={{ width: 175, marginTop: 15 }}
                options={[
                  {
                    value: "started",
                    label: t("orderStatus.started"),
                  },
                  {
                    value: "accepted",
                    label: t("orderStatus.accepted"),
                  },
                  {
                    value: "canceled",
                    label: t("orderStatus.canceled"),
                  },
                  {
                    value: "paid",
                    label: t("orderStatus.paid"),
                  },
                  {
                    value: "paidAndAccepted",
                    label: t("orderStatus.paidAndAccepted"),
                  },
                  {
                    value: "completed",
                    label: t("orderStatus.completed"),
                  },
                ]}
              />
              {orderOnSave && <Spinner size="normal" color={textColors.main} />}
              {!orderOnSave && (
                <SaveTwoTone
                  onClick={onOrderSave}
                  style={{ cursor: "pointer", fontSize: 24, marginTop: 15 }}
                />
              )}
            </Space>
          )}
        </Space>
      }
      footer={null}
      onCancel={() => {
        setActive(false);
      }}
    >
      <Layout>
        <Content style={{ background: "white" }}>
          <Skeleton loading={loading}>
            {loading && <Spinner size="normal" color={textColors.main} />}
            {!loading && order && (orderItems?.length || 0) === 0 && (
              <h3>{t("message.emptyOrder")}</h3>
            )}
            {(orderItems?.length || 0) > 0 && (
              <React.Fragment>
                <Row style={{ borderBottom: "1px solid", height: "45px" }}>
                  <Space
                    style={{
                      minWidth: 100,
                      width: isMobile
                        ? window.innerWidth >= 390
                          ? 120
                          : 100
                        : undefined,
                      maxWidth: "240px",
                      borderRight: "1px solid",
                      fontWeight: 600,
                      flexGrow: isMobile ? "inherit" : 1,
                    }}
                  >
                    {t("label.title")}
                  </Space>
                  <Space
                    style={{
                      borderRight: "1px solid",
                      padding: 10,
                      width: 80,
                      fontWeight: 600,
                    }}
                  >
                    {t("label.price")}
                  </Space>
                  <Space
                    style={{
                      borderRight: "1px solid",
                      padding: 10,
                      width: 60,
                      fontWeight: 600,
                    }}
                  >
                    {t("table.column.quantity")}
                  </Space>
                  <Space
                    style={{
                      padding: 10,
                      fontWeight: 600,
                    }}
                  >
                    {t("label.total")}
                  </Space>
                </Row>
                {orderItems.map((orderItem: OrderItem, index) => {
                  return (
                    <Row
                      key={`order-item-${index}`}
                      style={{ borderBottom: "1px solid", height: "45px" }}
                    >
                      <Space
                        style={{
                          minWidth: 100,
                          width: isMobile
                            ? window.innerWidth >= 390
                              ? 120
                              : 100
                            : undefined,
                          maxWidth: "240px",
                          borderRight: "1px solid",
                          flexGrow: isMobile ? "inherit" : 1,
                        }}
                      >
                        {orderItem.menuItemTitle}
                      </Space>
                      <Space
                        style={{
                          borderRight: "1px solid",
                          padding: 10,
                          width: 80,
                        }}
                      >{`${orderItem.price}`}</Space>
                      <Space
                        style={{
                          borderRight: "1px solid",
                          padding: 10,
                          width: 60,
                        }}
                      >{`x${orderItem.quantity}`}</Space>
                      <Space
                        style={{
                          padding: 10,
                        }}
                      >{`${orderItem.total}`}</Space>
                    </Row>
                  );
                })}
                <Row style={{ height: 50 }} justify="end" align="middle">
                  <Space style={{ width: "70%" }}>
                    {order?.additionalDetails && (
                      <Space>
                        <b>{t("label.additionalDetails")}:</b>
                        {order?.additionalDetails}
                      </Space>
                    )}
                  </Space>
                  <Space style={{ width: "30%" }}>
                    <span>
                      <b>{t("label.total")}</b>
                    </span>
                    <span
                      style={{ display: "block", width: 80 }}
                    >{`${order?.total}`}</span>
                  </Space>
                </Row>
              </React.Fragment>
            )}
          </Skeleton>
        </Content>
      </Layout>
    </Modal>
  );
};
