import { API_BASE_URL } from "src/constants";
import { getUnauthorized } from "src/core/utils/api";
import { MenuDesign } from "src/types/types";

const createMenuDesignService = () => {
  const getMenuDesign = async (identifier: string): Promise<MenuDesign> => {
    const response = await getUnauthorized<MenuDesign>(
      API_BASE_URL + `/menus/${identifier}/menu-designs`
    );

    return response;
  };

  const getMenuDesigns = async (): Promise<MenuDesign[]> => {
    const response = await getUnauthorized<MenuDesign[]>(
      API_BASE_URL + `/menu-designs`
    );

    return response.filter((el) => !el.private);
  };

  return {
    getMenuDesign,
    getMenuDesigns,
  };
};

export const menuDesignService = createMenuDesignService();
