import { ReloadOutlined } from "@ant-design/icons";
import { Button, Layout, notification, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useInterval } from "src/core/hooks/useInterval";
import { orderService } from "src/core/services/orderService";
import { LeftSidebar } from "src/modules/leftSidebar/LeftSidebar";
import { OrdersTable } from "src/modules/ordersTable/OrdersTable";
import { ContentPageHeader } from "src/modules/pageHeader/PageHeader";
import { Order } from "src/types/types";

const { Content } = Layout;

export const UserOrdersPage = () => {
  const [loading, setLoading] = useState(true);
  const [userOrders, setUserOrders] = useState<Order[]>([]);

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      await refreshOrders();

      setLoading(false);
    })();
  }, []);

  const refreshOrders = async () => {
    setLoading(true);

    const response = await orderService.getUserOrders();
    setUserOrders(response);

    setLoading(false);
  };

  const refreshOrdersSilently = async () => {
    const response =
      userOrders.length > 0
        ? await orderService.getNewUserOrders(userOrders[0].id)
        : await orderService.getNewUserOrders();

    if (response.length > 0) {
      const mergedOrders = response.concat(userOrders);
      setUserOrders(mergedOrders);

      response.forEach((ord: Order) => {
        notification.success({
          message: t("label.newOrder"),
          description: `${t("message.orderNumber")}: ${ord.orderNumber}`,
          onClick: () => {},
          top: 90,
          duration: 60,
        });
      });
    }
  };

  const onEditSuccess = async () => {
    const response = await orderService.getUserOrders();
    setUserOrders(response);
  };

  var buttons = [];

  buttons.push(
    <Button
      onClick={async () => {
        await refreshOrders();
      }}
      disabled={loading}
      key="refresh-button"
    >
      <ReloadOutlined /> {t("button.refresh")}
    </Button>
  );

  useInterval(() => {
    refreshOrdersSilently();
  }, 60000);

  return (
    <Layout>
      <LeftSidebar page="orders" />
      <Content style={{ textAlign: "center", padding: "5px" }}>
        <Layout>
          <ContentPageHeader pageTitle={t("title.orders")} buttons={buttons} />
          <Skeleton active loading={loading}>
            {userOrders.length === 0 && (
              <Content style={{ textAlign: "left", paddingLeft: 25 }}>
                <h3>{t("message.ordersIntro")}</h3>
              </Content>
            )}
            <Content style={{ padding: 25 }}>
              <OrdersTable orders={userOrders} onEditSuccess={onEditSuccess} />
            </Content>
          </Skeleton>
        </Layout>
      </Content>
    </Layout>
  );
};
