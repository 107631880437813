import { API_BASE_URL, ORDER_BASE_URL } from "src/constants";
import { post } from "src/core/utils/api";
import { Order, PaymentInfo } from "src/types/types";

const createPaymentService = () => {
  const generateOrderPaymentLink = async (
    order: Order
  ): Promise<string | null> => {
    const response = await post<PaymentInfo>(
      API_BASE_URL + `/payments/orders/${order.orderNumber}`,
      { returnUrl: `${ORDER_BASE_URL}/${order.orderNumber}` }
    );

    if (response) {
      return response.paymentLink;
    } else {
      return null;
    }
  };

  const processPaymentToken = async (paymentToken: string): Promise<void> => {
    await post<PaymentInfo>(
      API_BASE_URL + `/callbacks/payments?payment_token=${paymentToken}`,
      {}
    );
  };

  return {
    generateOrderPaymentLink,
    processPaymentToken,
  };
};
export const paymentService = createPaymentService();
