import {
  Button,
  Input,
  InputNumber,
  Layout,
  Modal,
  notification,
  Radio,
  Space,
} from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MONTHLY_PRICE } from "src/constants";
import { Spinner } from "src/core/components/Spinner";
import { paymentOrderService } from "src/core/services/paymentOrderService";
import { textColors } from "src/core/style/colors";
import { PaymentOrder, User } from "src/types/types";

type Props = {
  activeState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  user: User;
  onSuccess?: () => void;
};

const { TextArea } = Input;

export const PaymentOrderModal = (props: Props) => {
  const { user } = props;

  const [montsToExtendError, setMontsToExtendError] = useState(false);
  const [monthsToExtend, setMonthsToExtend] = useState(3);
  const [step, setStep] = useState(1);
  const [oneMonthPrice, setOneMonthPrice] = useState(
    user.monthlyPrice ?? MONTHLY_PRICE
  );
  const [totalAmount, setTotalAmount] = useState(
    oneMonthPrice * monthsToExtend
  );

  const [paymentOrder, setPaymentOrder] = useState<PaymentOrder>({
    total: 0,
    requestedForInvoice: false,
    additionalDetails: "",
  });

  const [submit, setSubmit] = useState(false);

  const { t } = useTranslation();

  const {
    activeState: [active, setActive],
  } = props;

  useEffect(() => {
    (async () => {
      setOneMonthPrice(user.monthlyPrice ?? MONTHLY_PRICE);
      setTotalAmount(oneMonthPrice * monthsToExtend);
    })();
  }, [monthsToExtend, oneMonthPrice, user.monthlyPrice]);

  const createPaymentOrder = async () => {
    setSubmit(true);
    setMontsToExtendError(false);

    paymentOrder.total = totalAmount;

    const result = await paymentOrderService.createPaymentOrder(paymentOrder);

    setPaymentOrder(result);
    setStep(2);

    setSubmit(false);

    notification.success({
      message: t("label.info"),
      description: t("message.paymentOrderCreated"),
      onClick: () => {},
      top: 30,
    });

    if (props.onSuccess) {
      props.onSuccess();
    }
  };

  return (
    <Modal
      open={active}
      title={t("modal.title.newPaymentOrder")}
      footer={null}
      onCancel={() => {
        setActive(false);
        setPaymentOrder({
          total: 0,
          requestedForInvoice: false,
          additionalDetails: "",
        });
        setStep(1);
        setMonthsToExtend(3);
        setTotalAmount(3 * oneMonthPrice);
      }}
    >
      <Layout>
        {step === 1 && (
          <Content>
            <Content style={{ background: "white", paddingBottom: 25 }}>
              <Radio.Group onChange={(ev) => {}} value={1}>
                <Space direction="vertical">
                  <Radio value={1}>{t("payment.bankTransfer")}</Radio>
                  <Radio value={2} disabled={true}>
                    {t("payment.fastTransfer")} {t("payment.comingSoon")}
                  </Radio>
                  <Radio value={3} disabled={true}>
                    {t("payment.payPal")} {t("payment.comingSoon")}
                  </Radio>
                </Space>
              </Radio.Group>
            </Content>
            <Content style={{ background: "white" }}>
              <Space>
                <p style={{ marginBottom: 20 }}>
                  <b>{t("payment.monthsToExtend")}:</b>
                </p>
                <InputNumber
                  placeholder={"0"}
                  value={monthsToExtend}
                  onChange={(value) => {
                    setMonthsToExtend(value ?? 1);
                    setTotalAmount(oneMonthPrice * (value ?? 1));
                  }}
                  style={{
                    marginBottom: 20,
                    borderColor: montsToExtendError ? "red" : undefined,
                  }}
                  min={1}
                />
                <Space
                  style={{
                    width: "100%",
                    flexDirection: "row-reverse",
                    minWidth: 150,
                  }}
                >
                  <p style={{ marginBottom: 20, minWidth: 100 }}>
                    <b style={{ fontSize: "20px", marginRight: 5 }}>
                      {t("payment.total")}:
                    </b>{" "}
                    {(totalAmount ?? 0).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })}{" "}
                    €
                  </p>
                </Space>
              </Space>
              <Space style={{ width: "100%" }}>
                <p style={{ marginBottom: 20 }}>
                  <b>{t("payment.requestInvoice")}:</b>
                </p>
                <Checkbox
                  checked={paymentOrder.requestedForInvoice}
                  onChange={(value) => {
                    setPaymentOrder({
                      ...paymentOrder,
                      requestedForInvoice: value.target.checked,
                    });
                  }}
                  style={{ marginBottom: 20 }}
                />
              </Space>
              {paymentOrder.requestedForInvoice && (
                <TextArea
                  placeholder={t("payment.invoiceDetails")}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  value={paymentOrder.additionalDetails}
                  onChange={(evt) => {
                    setPaymentOrder({
                      ...paymentOrder,
                      additionalDetails: evt.target.value,
                    });
                  }}
                  style={{ marginBottom: 20, width: "100%" }}
                />
              )}
              {paymentOrder.requestedForInvoice && (
                <Space style={{ width: "100%" }}>
                  <p style={{ marginBottom: 20 }}>
                    <b>{t("payment.sendInvoiceTo")}:</b>
                  </p>
                  <Input
                    type={"email"}
                    placeholder={t("input.email")}
                    value={paymentOrder.sendInvoiceTo}
                    onChange={(evt) => {
                      setPaymentOrder({
                        ...paymentOrder,
                        sendInvoiceTo: evt.target.value,
                      });
                    }}
                    style={{
                      marginBottom: 20,
                    }}
                  />
                </Space>
              )}
            </Content>
            <Content style={{ textAlign: "right", background: "white" }}>
              <Button
                type="primary"
                style={{ width: 100 }}
                onClick={() => {
                  createPaymentOrder();
                }}
                disabled={(totalAmount ?? 0) === 0 ? true : false}
              >
                {submit ? (
                  <Spinner size="normal" color={textColors.white} />
                ) : (
                  t("button.continue")
                )}
              </Button>
            </Content>
          </Content>
        )}
        {step === 2 && (
          <Content style={{ padding: 15 }}>
            <p>{t("payment.step2Intro")}</p>
            <p>
              <b>{t("payment.receiver")}:</b> {t("payment.receiverName")}
            </p>
            <p>
              <b>{t("payment.bankAccount")}:</b>{" "}
              {t("payment.receiverBankAccount")}
            </p>
            <p>
              <b>{t("payment.paymentPurpose")}:</b> {paymentOrder.paymentId}
            </p>
            <p>
              <b>{t("payment.amount")}:</b>{" "}
              {paymentOrder.total?.toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}{" "}
              €
            </p>
            <p>{t("payment.step2note")}</p>
            <p>{t("payment.finalNote")}</p>
            {paymentOrder.requestedForInvoice && (
              <p>{t("payment.invoiceNote")}</p>
            )}
          </Content>
        )}
      </Layout>
    </Modal>
  );
};
