import { API_BASE_URL } from "src/constants";
import { postUnauthorized } from "src/core/utils/api";
import { Log } from "src/types/types";

const createLoggingService = () => {
  const createLog = async (log: Log): Promise<void> => {
    await postUnauthorized<any>(API_BASE_URL + `/logs`, log);
  };

  return {
    createLog,
  };
};
export const loggingService = createLoggingService();
