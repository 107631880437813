import { UnorderedListOutlined } from "@ant-design/icons";
import {
  Button,
  Input,
  InputNumber,
  Layout,
  Modal,
  notification,
  Select,
  Space,
} from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Spinner } from "src/core/components/Spinner";
import { categoryService } from "src/core/services/categoryService";
import { menuService } from "src/core/services/menuService";
import { textColors } from "src/core/style/colors";
import { Category, MenuItem } from "src/types/types";

type Props = {
  menuId: number;
  activeState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  onSuccess?: () => void;
};

const { Option } = Select;
const { TextArea } = Input;

export const MenuItemCreateModal = (props: Props) => {
  const [newMenuItem, setNewMenuItem] = useState<MenuItem>({
    title: "",
    description: "",
    categoryId: undefined,
    price: undefined,
    secondPrice: undefined,
    menuId: props.menuId,
    orderNumber: 0,
    hidden: false,
    spicy: false,
    vegetarian: false,
    vegan: false,
    new: false,
    top: false,
  });

  const [titleRequiredError, setTitleRequiredError] = useState(false);
  const [categoryRequiredError, setCategoryRequiredError] = useState(false);
  const [priceRequiredError, setPriceRequiredError] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [currentMenuCategories, setCurrentMenuCategories] = useState<
    Category[]
  >([]);

  const [submit, setSubmit] = useState(false);

  const {
    activeState: [active, setActive],
  } = props;

  useEffect(() => {
    (async () => {
      if (props.menuId > 0) {
        const response = await categoryService.fetchUserMenuCategories(
          props.menuId
        );
        setCurrentMenuCategories(response);

        setLoading(false);
      }
    })();
  }, [props.menuId]);

  const createMenu = async () => {
    newMenuItem.menuId = props.menuId;

    setTitleRequiredError(false);
    setCategoryRequiredError(false);
    setPriceRequiredError(false);

    if (newMenuItem.title?.trim() === "") {
      setTitleRequiredError(true);
      return;
    }

    if (!newMenuItem.categoryId) {
      setCategoryRequiredError(true);
      return;
    }

    if (!newMenuItem.price) {
      setPriceRequiredError(true);
      return;
    }

    setSubmit(true);

    await menuService.createMenuItem(newMenuItem);
    setSubmit(false);

    if (props.onSuccess) {
      notification.success({
        message: t("label.info"),
        description: t("message.menuItemCreated"),
        onClick: () => {},
        top: 90,
        duration: 3,
      });

      props.onSuccess();
    }

    setNewMenuItem({
      title: "",
      description: "",
      categoryId: undefined,
      price: undefined,
      secondPrice: undefined,
      menuId: 0,
      orderNumber: 0,
      hidden: false,
      spicy: false,
      vegetarian: false,
      vegan: false,
      new: false,
      top: false,
    });
  };

  return (
    <Modal
      open={active}
      title={t("modal.title.newMenuItem")}
      footer={null}
      onCancel={() => {
        setNewMenuItem({
          title: "",
          description: "",
          categoryId: undefined,
          price: undefined,
          secondPrice: undefined,
          menuId: 0,
          orderNumber: 0,
          hidden: false,
        });
        setActive(false);
      }}
    >
      <Layout>
        <Content style={{ background: "white" }}>
          <Input
            autoFocus
            placeholder={t("input.title")}
            value={newMenuItem.title}
            onChange={(evt) => {
              setNewMenuItem({ ...newMenuItem, title: evt.target.value });
            }}
            style={{
              marginBottom: 20,
              borderColor: titleRequiredError ? "red" : undefined,
            }}
            required
          />
          <TextArea
            placeholder={t("input.descriptionOptional")}
            autoSize={{ minRows: 3, maxRows: 6 }}
            value={newMenuItem.description}
            onChange={(evt) => {
              setNewMenuItem({ ...newMenuItem, description: evt.target.value });
            }}
            style={{ marginBottom: 20 }}
          />
          <Space>
            <p style={{ marginBottom: 20 }}>
              <b>{t("input.orderNumber")}:</b>
            </p>
            <InputNumber
              placeholder={t("input.orderNumber")}
              value={newMenuItem.orderNumber}
              onChange={(value) => {
                setNewMenuItem({
                  ...newMenuItem,
                  orderNumber: value ?? 0,
                });
              }}
              style={{ marginBottom: 20 }}
            />
          </Space>
          {currentMenuCategories.length > 0 ? (
            <Select
              showSearch
              filterOption={(input, option) => {
                if (!Array.isArray(option?.children)) {
                  return (
                    (option?.children ?? "")
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                } else {
                  return (
                    option?.children[option?.children.length - 1]
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }
              }}
              loading={loading}
              value={newMenuItem.categoryId}
              placeholder={t("input.selectCategory")}
              style={{
                width: "100%",
                marginBottom: 20,
                border: categoryRequiredError ? "1px red solid" : undefined,
              }}
              onChange={(value: any) => {
                setNewMenuItem({ ...newMenuItem, categoryId: value });
              }}
            >
              {currentMenuCategories.map((cat: Category, index) => {
                const isParent = currentMenuCategories.find(
                  (c) => c.parentId === cat.id
                );

                return (
                  <Option
                    key={`menu-item-cat-${index}`}
                    value={cat.id || 0}
                    disabled={isParent !== undefined}
                  >
                    {(cat?.parentId || 0) > 0 && (
                      <span
                        style={{ width: 10, display: "inline-block" }}
                      ></span>
                    )}
                    <span
                      style={{
                        textDecoration: cat.hidden ? "line-through" : "none",
                      }}
                    >
                      {cat.title}
                    </span>
                  </Option>
                );
              })}
            </Select>
          ) : (
            <Space style={{ width: "100%", marginBottom: 15 }}>
              {t("message.menuItemNoCategories")}{" "}
              <Button
                onClick={() => {
                  history.push(`/admin/categories?menuId=${props.menuId}`);
                }}
              >
                {t("title.menuCategories")}
                {" ->"}
              </Button>
            </Space>
          )}

          <Input
            type="number"
            placeholder={t("input.price")}
            value={newMenuItem.price}
            onChange={(evt) =>
              setNewMenuItem({
                ...newMenuItem,
                price: evt.target.valueAsNumber,
              })
            }
            style={{
              marginBottom: 20,
              marginTop: 0,
              borderColor: priceRequiredError ? "red" : undefined,
            }}
            required
          />
          <Input
            type="number"
            placeholder={t("input.price2")}
            value={newMenuItem.secondPrice}
            onChange={(evt) =>
              setNewMenuItem({
                ...newMenuItem,
                secondPrice: evt.target.valueAsNumber,
              })
            }
            style={{ marginBottom: 30, marginTop: 0 }}
          />
          <Space style={{ width: "100%" }}>
            <p style={{ marginBottom: 20 }}>
              <b>{t("input.spicy")}:</b>
            </p>
            <Checkbox
              checked={newMenuItem.spicy}
              onChange={(value) => {
                setNewMenuItem({
                  ...newMenuItem,
                  spicy: value.target.checked,
                });
              }}
              style={{ marginBottom: 20, marginRight: 5 }}
            />
            <p style={{ marginBottom: 20 }}>
              <b>{t("input.vegetarian")}:</b>
            </p>
            <Checkbox
              checked={newMenuItem.vegetarian}
              onChange={(value) => {
                setNewMenuItem({
                  ...newMenuItem,
                  vegetarian: value.target.checked,
                });
              }}
              style={{ marginBottom: 20, marginRight: 5 }}
            />
            <p style={{ marginBottom: 20 }}>
              <b>{t("input.vegan")}:</b>
            </p>
            <Checkbox
              checked={newMenuItem.vegan}
              onChange={(value) => {
                setNewMenuItem({
                  ...newMenuItem,
                  vegan: value.target.checked,
                });
              }}
              style={{ marginBottom: 20 }}
            />
          </Space>
          <Space style={{ width: "100%" }}>
            <p style={{ marginBottom: 20 }}>
              <b>{t("input.new")}:</b>
            </p>
            <Checkbox
              checked={newMenuItem.new}
              onChange={(value) => {
                setNewMenuItem({
                  ...newMenuItem,
                  new: value.target.checked,
                });
              }}
              style={{ marginBottom: 20, marginRight: 5 }}
            />
            <p style={{ marginBottom: 20 }}>
              <b>{t("input.top")}:</b>
            </p>
            <Checkbox
              checked={newMenuItem.top}
              onChange={(value) => {
                setNewMenuItem({
                  ...newMenuItem,
                  top: value.target.checked,
                });
              }}
              style={{ marginBottom: 20, marginRight: 5 }}
            />
          </Space>
          <Space style={{ width: "100%" }}>
            <p style={{ marginBottom: 30 }}>
              <b>{t("input.hidden")}:</b>
            </p>
            <Checkbox
              checked={newMenuItem.hidden}
              onChange={(value) => {
                setNewMenuItem({
                  ...newMenuItem,
                  hidden: value.target.checked,
                });
              }}
              style={{ marginBottom: 30 }}
            />
          </Space>
        </Content>
        <Content style={{ textAlign: "right", background: "white" }}>
          <Button
            style={{ width: 160, marginRight: 20 }}
            onClick={() => {
              history.push(`/admin/categories?menuId=${props.menuId}`);
            }}
            disabled={false}
          >
            <UnorderedListOutlined />
            {t("title.menuCategories")}
          </Button>
          <Button
            type="primary"
            style={{ width: 100 }}
            onClick={() => {
              createMenu();
            }}
            disabled={currentMenuCategories.length > 0 ? false : true}
          >
            {submit ? (
              <Spinner size="small" color={textColors.white} />
            ) : (
              t("button.save")
            )}
          </Button>
        </Content>
      </Layout>
    </Modal>
  );
};
