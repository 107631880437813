import { ShoppingCartOutlined } from "@ant-design/icons";
import { Affix, Badge, Col, Layout, Row, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { getCartId } from "src/cartProvider";
import { loggingService } from "src/core/services/loggingService";
import { menuService } from "src/core/services/menuService";
import { cartService } from "src/core/services/orderCartService";
import { MenuNavigationSidebar } from "src/modules/menuNavigationSidebar/MenuNavigationSidebar";
import { OrderCartSidebar } from "src/modules/orderCartSidebar/OrderCartSidebar";
import { MenuHeader } from "src/pages/menuPage/components/MenuHeader";
import { MenuItemsBlock } from "src/pages/menuPage/components/MenuItemsBlock";
import { SectionsBlock } from "src/pages/menuPage/components/SectionsBlock";
import { SocialIcons } from "src/pages/menuPage/components/SocialIcons";
import { orderCartState } from "src/recoil/atoms";
import { Menu, MenuDesign, MenuItemsGrouped } from "src/types/types";

const { Content } = Layout;

type Props = {
  menuIdentifier: string;
  menuDesign?: MenuDesign;
};

export const MenuContent = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [showMenuNavigation, setShowMenuNavigation] = useState(false);
  const [showCartSidebar, setShowCartSidebar] = useState(false);

  const [orderCart, setOrderCart] = useRecoilState(orderCartState);

  const [menu, setMenu] = useState<Menu>();
  const [menuItemsGrouped, setMenuItemsGrouped] = useState<MenuItemsGrouped>();

  const { menuIdentifier, menuDesign } = props;
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const orderCartId = getCartId();

      if (orderCartId) {
        const response = await cartService.getCartItems(orderCartId);
        setOrderCart({ cartId: orderCartId, items: response });
      }
    })();
  }, [setOrderCart]);

  useEffect(() => {
    (async () => {
      const start = Date.now();

      const groupedMenuItems = await menuService.fetchMenuItemsGrouped(
        menuIdentifier
      );

      if (groupedMenuItems.pdfLink && groupedMenuItems.pdfLink !== "") {
        window.location.replace(groupedMenuItems.pdfLink);
        return;
      }

      if (groupedMenuItems.menuFound) {
        setMenu({
          title: groupedMenuItems.title,
          description: groupedMenuItems.description,
          headerLogo: groupedMenuItems.headerLogo,
          showSidebar: groupedMenuItems.showSidebar,
          ordersEnabled: groupedMenuItems.ordersEnabled,
        });
      } else {
        setMenu({
          title: t("warning.menuNotFoundTitle"),
          description: t("warning.menuNotFoundDescription"),
        });
      }

      document.title = `${groupedMenuItems.title} | ${t("common.urlLabel")}`;

      setMenuItemsGrouped(groupedMenuItems);

      setLoading(false);

      const duration = Date.now() - start;

      await loggingService.createLog({
        type:
          groupedMenuItems?.sections?.length > 0 ? "multiMenuOpen" : "menuOpen",
        value: menuIdentifier,
        elapsedTime: Math.round(duration),
      });

      if (!groupedMenuItems.menuFound) {
        await loggingService.createLog({
          type: "errorMenuNotFound",
          value: menuIdentifier,
          elapsedTime: Math.round(duration),
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onMenuNavigationClose = () => {
    setShowMenuNavigation(false);
  };

  const onCartSidebarClose = () => {
    setShowCartSidebar(false);
  };

  return (
    <Skeleton active loading={loading}>
      <MenuNavigationSidebar
        showDrawer={showMenuNavigation}
        onClose={onMenuNavigationClose}
        menuItemsGrouped={menuItemsGrouped}
        menuDesign={menuDesign}
        headerLogo={
          menu?.headerLogo && menu.headerLogo !== ""
            ? menu?.headerLogo
            : undefined
        }
      />
      <OrderCartSidebar
        menuIdentifier={menuIdentifier}
        showDrawer={showCartSidebar}
        onClose={onCartSidebarClose}
      />
      {/* Gaisrine restobar social icons */}
      {!showMenuNavigation && menuIdentifier === "62d83e9783f00" && (
        <SocialIcons
          size={40}
          tripAdvisor="https://www.tripadvisor.com/Restaurant_Review-g274950-d24133336-Reviews-Palangos_Svyturys-Palanga_Klaipeda_County.html"
          facebook="https://www.facebook.com/palangossvyturys/"
          google="https://www.google.com/maps/place/Palangos+%C5%A0vyturys/@55.9199566,21.049692,17z/data=!3m1!4b1!4m5!3m4!1s0x46e5291b86dd8971:0xbdeefeb14e43b835!8m2!3d55.9199566!4d21.051886"
          instagram="https://www.instagram.com/svyturys.palanga"
          menu={menu}
        />
      )}
      {!showMenuNavigation && menuIdentifier === "61fa9c9ecac6a" && (
        <SocialIcons
          size={40}
          tripAdvisor="https://www.tripadvisor.com/Restaurant_Review-g274949-d21272533-Reviews-Gaisrine_Restobar-Klaipeda_Klaipeda_County.html"
          facebook="https://www.facebook.com/gaisrinerestobaras"
          google="https://www.google.com/maps/place/Gaisrin%C4%97+%7C+Restobaras/@55.7092384,21.1296021,17z/data=!4m12!1m6!3m5!1s0x46e4db76b01b2349:0xd44702f4efc8748e!2sGaisrin%C4%97+%7C+Restobaras!8m2!3d55.7092354!4d21.1317961!3m4!1s0x46e4db76b01b2349:0xd44702f4efc8748e!8m2!3d55.7092354!4d21.1317961"
          instagram="https://www.instagram.com/gaisrinerestobaras"
          menu={menu}
        />
      )}
      {!showMenuNavigation && menuIdentifier === "628a4ccab323b" && (
        <SocialIcons
          size={40}
          facebook="https://www.facebook.com/Kompotas-107106894028333"
          instagram="https://www.instagram.com/bykompotas"
          menu={menu}
        />
      )}
      <MenuHeader
        menu={menu}
        showMenuNavigation={showMenuNavigation}
        setShowMenuNavigation={setShowMenuNavigation}
      />
      <Content
        style={{
          margin: "0 auto",
          width: "100%",
        }}
      >
        <Row justify={"center"}>
          <Col span={24}>
            <SectionsBlock sections={menuItemsGrouped?.sections} />
            <MenuItemsBlock
              menuItemsCategories={menuItemsGrouped?.items}
              ordersEnabled={menu?.ordersEnabled}
            />
          </Col>
        </Row>
      </Content>
      {orderCart?.items?.length > 0 && (
        <Affix
          style={{
            position: "fixed",
            top: 20,
            left: 15,
            height: 40,
            width: 45,
            padding: 5,
            backgroundColor: "white",
            borderRadius: "25px",
          }}
        >
          <Badge count={orderCart?.items?.length ?? 0} offset={[5, 8]}>
            <ShoppingCartOutlined
              style={{ fontSize: "32px" }}
              title={t("label.orderCart")}
              onClick={() => {
                setShowCartSidebar(!showCartSidebar);
              }}
            />
          </Badge>
        </Affix>
      )}
    </Skeleton>
  );
};
