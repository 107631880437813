import { Layout } from "antd";
import React, { useEffect, useState } from "react";
import { RecoilRoot } from "recoil";
import { App } from "src/App";
import { Spinner } from "src/core/components/Spinner";

export const InitializationPage = () => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    (async () => {
      setInitialized(true);
    })();
  }, []);

  if (initialized) {
    return (
      <RecoilRoot>
        <App />
      </RecoilRoot>
    );
  }

  return (
    <Layout>
      <Spinner size="larger" delay={1000} />
    </Layout>
  );
};
