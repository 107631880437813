import { PDFDocumentProxy } from "pdfjs-dist/types/src/display/api";
import { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

export default function AllPagesViewer(props: any) {
  const [numPages, setNumPages] = useState(1);

  function onDocumentLoadSuccess(pdfObject: PDFDocumentProxy) {
    setNumPages(pdfObject.numPages ?? 1);
    debugger;
  }

  const { pdf } = props;

  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  return (
    <Document file={pdf} options={{}} onLoadSuccess={onDocumentLoadSuccess}>
      {Array.from(new Array(numPages), (el, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
      ))}
    </Document>
  );
}
