import { Button, Layout, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { OVERWRITE_HOME_PAGE } from "src/constants";
import { PageFooter } from "src/modules/pageFooter/PageFooter";

const { Header, Content } = Layout;

export const HomePage = () => {
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      if (OVERWRITE_HOME_PAGE !== "") {
        window.location.replace(OVERWRITE_HOME_PAGE);
      } else {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Layout>
      <Skeleton active loading={loading}>
        <Header
          style={{
            background: "white",
            height: 80,
            textAlign: "center",
            lineHeight: "20px",
            padding: "25px",
          }}
        >
          <h1>{t("home.header")}</h1>
        </Header>
        <Content style={{ textAlign: "center", padding: "50px" }}>
          <div>
            <h2>{t("home.title")}</h2>
            <p>{t("home.subTitle")}</p>
          </div>
        </Content>
        <Content style={{ textAlign: "center", padding: "20px" }}>
          <Button
            type="link"
            style={{ width: "100px" }}
            onClick={() => {
              history.push("/signin");
            }}
          >
            {t("button.signIn")}
          </Button>
          {" | "}
          <Button
            type="link"
            style={{ width: "100px" }}
            onClick={() => {
              history.push("/register");
            }}
          >
            {t("button.register")}
          </Button>
        </Content>
        <PageFooter />
      </Skeleton>
    </Layout>
  );
};
