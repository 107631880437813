import { Card, Layout, Row, Space } from "antd";
import React, { useEffect } from "react";
import { useViewPortQuery } from "src/core/hooks/useViewPortQuery";
import { getPublicMenuLink } from "src/core/utils/menuLink";
import { MultiMenuSection } from "src/types/types";

const { Content } = Layout;

type Props = {
  sections?: MultiMenuSection[];
};

export const SectionsBlock = (props: Props) => {
  const { sections } = props;

  const isMobile = useViewPortQuery() === "mobile";

  useEffect(() => {
    (async () => {})();
  }, []);

  return (
    <Row justify={"center"} style={{}}>
      <Space wrap style={{ display: isMobile ? "block" : "" }}>
        {sections?.map((section, index) => {
          return (
            <Content
              key={`section-content-${index}`}
              className={
                index % 2 !== 0
                  ? "menu-section-child-1"
                  : "menu-section-child-2"
              }
              style={{
                textAlign: "center",
                padding: "20px",
                margin: 15,
                borderRadius: "10px",
              }}
            >
              <Content>
                <Card
                  className="multi-menu-title"
                  key={`section-${index}`}
                  style={{
                    background: index % 2 !== 0 ? "#f7fffa66" : "#f5f6f766",
                    minWidth: 150,
                    border: "solid #86888b90 1px",
                    borderRadius: "10px",
                  }}
                  onClick={() => {
                    window.location.href = getPublicMenuLink(
                      section.menuIdentifier
                    );
                  }}
                  bordered={true}
                  hoverable={true}
                >
                  <b>{section.title}</b>
                  {section?.description && (
                    <p style={{ margin: 0, fontSize: 12 }}>
                      {section.description}
                    </p>
                  )}
                </Card>
              </Content>
            </Content>
          );
        })}
      </Space>
    </Row>
  );
};
