import { DeleteOutlined } from "@ant-design/icons";
import { Button, Drawer, notification, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { Content } from "antd/lib/layout/layout";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { getCartId, setCartId } from "src/cartProvider";
import { Spinner } from "src/core/components/Spinner";
import { cartService } from "src/core/services/orderCartService";
import { orderService } from "src/core/services/orderService";
import { messagingColors } from "src/core/style/colors";
import { getQueryParamValue } from "src/core/utils/utils";
import { orderCartState } from "src/recoil/atoms";
import { OrderItem } from "src/types/types";

type Props = {
  onClose: () => void;
  showDrawer: boolean;
  menuIdentifier: string;
};

export const OrderCartSidebar = (props: Props) => {
  const { showDrawer, onClose, menuIdentifier } = props;

  const [orderCart, setOrderCart] = useRecoilState(orderCartState);
  const [customerNote, setCustomerNote] = useState("");
  const [submit, setSubmit] = useState(false);

  const { t } = useTranslation();

  const clearOrderCart = () => {
    setCartId("");

    setOrderCart(() => {
      return {
        cartId: "0",
        items: [],
      };
    });
  };

  const calculateOrderTotal = (items: OrderItem[]): string => {
    let total = 0;
    items.forEach((item) => {
      total = total + (item?.price ?? 0) * item.quantity;
    });

    return (Math.round(total * 100) / 100).toFixed(2);
  };

  const saveOrder = async () => {
    setSubmit(true);

    const tableId = getQueryParamValue("t");

    const order = await orderService.generateOrder(
      orderCart.items,
      menuIdentifier,
      orderCart.cartId,
      customerNote,
      tableId
    );

    clearOrderCart();

    setSubmit(false);

    onClose();

    notification.success({
      message: t("label.thankYou"),
      description: `${t("message.orderCreated")} ${t("label.orderNumber")}: ${
        order.orderNumber
      }`,
      onClick: () => {},
      top: 30,
      duration: 3,
    });

    //window.open(`/o/${order.orderNumber}`);
    //openTab(`/o/${order.orderNumber}`);
    window.location.href = `/o/${order.orderNumber}`;
  };

  const deleteOrderCartItem = async (itemId: number) => {
    const orderCartId = getCartId();

    if (orderCartId) {
      const updatedOrderCart = {
        cartId: orderCart.cartId,
        items: orderCart.items.filter((x) => x.id !== itemId),
      };

      setOrderCart(updatedOrderCart);

      await cartService.deleteCartItem(orderCartId, itemId);
    }
  };

  const openTab = (url: string) => {
    // Create link in memory
    var a = window.document.createElement("a");
    a.target = "_blank";
    a.href = url;

    // Dispatch fake click
    var e = window.document.createEvent("MouseEvents");
    e.initMouseEvent(
      "click",
      true,
      true,
      window,
      0,
      0,
      0,
      0,
      0,
      false,
      false,
      false,
      false,
      0,
      null
    );
    a.dispatchEvent(e);
  };

  return (
    <React.Fragment>
      <Drawer
        title={t("title.orderCart")}
        width={290}
        placement="left"
        onClose={onClose}
        open={showDrawer}
        bodyStyle={{ paddingTop: 0 }}
        headerStyle={{ paddingBottom: 0 }}
        className={"sidebar-navigation"}
      >
        <Content style={{ marginTop: "15px" }}>
          {orderCart?.items?.length === 0 && (
            <h3>{t("message.cartIsEmpty")}</h3>
          )}
          {orderCart?.items?.map((cartItem, index) => {
            return (
              <React.Fragment key={`cart-i-i-${index}`}>
                <Space
                  style={{
                    borderBottom: "1px solid",
                    paddingBottom: "10px",
                    paddingTop: "5px",
                  }}
                >
                  <Space style={{ fontWeight: 600, width: "110px" }}>
                    {cartItem.menuItemTitle}
                  </Space>{" "}
                  |{" "}
                  <Space
                    style={{ width: "10px" }}
                  >{`x${cartItem.quantity}`}</Space>{" "}
                  | <Space>{`${cartItem.price}`}€</Space>
                  <Space>
                    <DeleteOutlined
                      style={{
                        cursor: "pointer",
                        color: "gray",
                        right: 0,
                      }}
                      onClick={() => {
                        deleteOrderCartItem(cartItem.id ?? 0);
                      }}
                    />
                  </Space>
                </Space>
              </React.Fragment>
            );
          })}
          <Content style={{ marginTop: "25px", textAlign: "right" }}>
            {orderCart?.items?.length > 0 && (
              <Space style={{ width: "100%" }}>
                <TextArea
                  style={{ width: 240, marginBottom: 20 }}
                  rows={2}
                  placeholder={t("label.note")}
                  maxLength={200}
                  value={customerNote}
                  onChange={(event) => {
                    setCustomerNote(event.target.value);
                  }}
                />
              </Space>
            )}
            {orderCart?.items?.length > 0 && (
              <Space style={{ marginTop: 5, marginBottom: 20 }}>
                <b>{t("label.total")}</b>
                {calculateOrderTotal(orderCart.items)}
              </Space>
            )}
            <Space>
              <Button
                style={{ width: 80 }}
                onClick={() => {
                  clearOrderCart();
                }}
                disabled={orderCart?.items?.length === 0 ? true : false}
              >
                {t("button.clearCart")}
              </Button>
              <Button
                type="primary"
                style={{ width: 100 }}
                onClick={() => {
                  saveOrder();
                }}
                disabled={orderCart?.items?.length === 0 ? true : false}
              >
                {submit ? (
                  <Spinner size="normal" color={messagingColors.ctaBlue} />
                ) : (
                  t("button.saveOrder")
                )}
              </Button>
            </Space>
          </Content>
        </Content>
      </Drawer>
    </React.Fragment>
  );
};
