import { API_BASE_URL } from "src/constants";
import { post } from "src/core/utils/api";
import { PaymentOrder } from "src/types/types";

const createPaymentOrderService = () => {
  const createPaymentOrder = async (
    payment: PaymentOrder
  ): Promise<PaymentOrder> => {
    const response = await post<PaymentOrder>(
      API_BASE_URL + `/payments`,
      payment
    );

    return response;
  };

  return {
    createPaymentOrder,
  };
};
export const paymentOrderService = createPaymentOrderService();
