import { API_BASE_URL } from "src/constants";
import { destroy, get, getUnauthorized, post, put } from "src/core/utils/api";
import { ActionResponse, Category } from "src/types/types";

const createCategoryService = () => {
  const fetchMenuCategories = async (
    identifier: string
  ): Promise<Category[]> => {
    const response = await getUnauthorized<Category[]>(
      API_BASE_URL + `/menus/${identifier}/categories`
    );

    return response;
  };

  const fetchUserCategories = async (): Promise<Category[]> => {
    const response = await get<Category[]>(
      API_BASE_URL + `/users/me/categories`
    );

    return response;
  };

  const fetchUserCategoriesByMenu = async (
    identifier: string,
    returnParents: string = "0"
  ): Promise<Category[]> => {
    const response = await get<Category[]>(
      API_BASE_URL +
        `/categories/menus/${identifier}?returnParents=${returnParents}`
    );

    return response;
  };

  const fetchUserCategoriesByMenuId = async (
    id: number
  ): Promise<Category[]> => {
    const response = await get<Category[]>(
      API_BASE_URL + `/users/me/menus/${id}/categories`
    );

    return response;
  };

  const fetchUserMenuCategories = async (
    menuId: number
  ): Promise<Category[]> => {
    const response = await get<Category[]>(
      API_BASE_URL + `/users/me/menus/${menuId}/categories`
    );

    return response;
  };

  const createCategory = async (category: Category): Promise<Category> => {
    const response = await post<Category>(
      API_BASE_URL + `/users/me/categories`,
      category
    );

    return response;
  };

  const updateCategory = async (category: Category): Promise<Category> => {
    const response = await put<Category>(
      API_BASE_URL + `/users/me/categories/${category.id}`,
      category
    );

    return response;
  };

  const deleteCategory = async (
    id: number,
    menuId: number,
    forceDelete: boolean = false
  ): Promise<boolean> => {
    const response = await destroy<ActionResponse>(
      API_BASE_URL +
        `/users/me/categories/${id}/${menuId}?forceDelete=${forceDelete}`
    );

    return response.error === undefined;
  };

  return {
    fetchMenuCategories,
    fetchUserCategories,
    createCategory,
    updateCategory,
    deleteCategory,
    fetchUserMenuCategories,
    fetchUserCategoriesByMenu,
    fetchUserCategoriesByMenuId,
  };
};
export const categoryService = createCategoryService();
