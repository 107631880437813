import { AutoComplete, Button, Layout, Select, Skeleton, Space } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { categoryService } from "src/core/services/categoryService";
import { menuService } from "src/core/services/menuService";
import { getQueryParamValue } from "src/core/utils/utils";
import { LeftSidebar } from "src/modules/leftSidebar/LeftSidebar";
import { MenuItemCreateModal } from "src/modules/menuItemCreateModal/MenuItemCreateModal";
import { MenusItemsTable } from "src/modules/menuItemsTable/MenusItemsTable";
import { ContentPageHeader } from "src/modules/pageHeader/PageHeader";
import { Category, MenuItem } from "src/types/types";

const { Content } = Layout;
const { Option } = Select;

export const UserMenuItemsPage = () => {
  const [loading, setLoading] = useState(true);
  const [menuId, setMenuId] = useState(0);
  const [filterCategoryId, setFilterCategoryId] = useState<number>(0);

  const [userMenuItems, setUserMenuItems] = useState<MenuItem[]>([]);
  const [filteredUserMenuItems, setFilteredUserMenuItems] = useState<
    MenuItem[]
  >([]);

  const [userCategories, setUserCategories] = useState<Category[]>([]);
  const [userAllCategories, setUserAllCategories] = useState<Category[]>([]);
  const [createMenuItem, setCreateMenuItem] = useState(false);

  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentMenuTitle, setCurrentMenuTitle] = useState(
    getQueryParamValue("t")
  );

  const [searchOptions, setSearchOptions] = useState<
    { value: string; key: string }[]
  >([]);
  const [filteredSearchOptions, setFilteredSearchOptions] = useState<
    { value: string }[]
  >([]);

  const [searchValue, setSearchValue] = useState("");

  let { id }: any = useParams();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      setMenuId(id);

      const response = await menuService.fetchMenuItems(id);
      setUserMenuItems(response);
      setFilteredUserMenuItems(response);

      const categories = await categoryService.fetchUserMenuCategories(id);
      setUserCategories(categories);

      const allCategories = await categoryService.fetchUserCategories();
      setUserAllCategories(allCategories);

      setLoading(false);
    })();
  }, [id]);

  useEffect(() => {
    (async () => {
      let searchOptions: { value: string; key: string }[] = [];

      filteredUserMenuItems.forEach((item: MenuItem) => {
        if (
          searchOptions.indexOf({
            value: item.title,
            key: `${item.title}-${item.id}`,
          }) < 0
        ) {
          searchOptions.push({
            value: item.title,
            key: `${item.title}-${item.id}`,
          });
        }
      });

      setSearchOptions(searchOptions);
      setFilteredSearchOptions(searchOptions);
    })();
  }, [filteredUserMenuItems]);

  var buttons = [];

  buttons.push(
    <Button
      onClick={() => {
        setCreateMenuItem(true);
      }}
      key="add-button"
      type="primary"
    >
      {t("button.newMenuItem")}
    </Button>
  );

  buttons.push(
    <Button
      onClick={() => {
        history.push("/admin/menus");
      }}
      key="back-button"
      type="primary"
    >
      {t("button.back")}
    </Button>
  );

  const onSearch = (searchText: string) => {};

  const onSearchSelect = (value: string) => {
    if (typeof value !== "undefined" && value !== "") {
      const filteredItems = filteredUserMenuItems.filter(
        (e) => e?.title?.toLowerCase().indexOf(value?.toLowerCase()) >= 0
      );

      setFilteredUserMenuItems(filteredItems);
    }
  };
  const onSearchChange = (value: string) => {
    if (typeof value !== "undefined" && value !== "") {
      const filteredItems = searchOptions.filter(
        (e) => e?.value?.toLowerCase().indexOf(value?.toLowerCase()) >= 0
      );

      setFilteredSearchOptions(filteredItems);
    } else {
      if (filterCategoryId > 0) {
        const filteredItems = userMenuItems.filter(
          (e) => e.categoryId === filterCategoryId
        );

        setFilteredUserMenuItems(filteredItems);
        let searchOptions = filteredUserMenuItems.map((item: MenuItem) => {
          return { value: item.title, key: `${item.title}-${item.id}` };
        });

        setSearchOptions(searchOptions);
        setFilteredSearchOptions(searchOptions);
        setSearchValue("");
      } else {
        setFilteredUserMenuItems(userMenuItems);

        let searchOptions = userMenuItems.map((item: MenuItem) => {
          return { value: item.title, key: `${item.title}-${item.id}` };
        });

        setSearchOptions(searchOptions);
        setFilteredSearchOptions(searchOptions);
        setSearchValue("");
      }

      let filteredItems = filteredUserMenuItems.map((item: MenuItem) => {
        return { value: item.title, key: `${item.title}-${item.id}` };
      });

      setFilteredSearchOptions(filteredItems);
    }

    setSearchValue(value);
  };

  const onMenuItemCreated = async () => {
    setCreateMenuItem(false);
    setLoading(true);

    const response = await menuService.fetchMenuItems(id);
    setUserMenuItems(response);

    if (filterCategoryId > 0) {
      const filteredItems = response.filter(
        (e) => e.categoryId === filterCategoryId
      );

      setFilteredUserMenuItems(filteredItems);
    } else {
      setFilteredUserMenuItems(response);
    }

    const categories = await categoryService.fetchUserMenuCategories(id);
    setUserCategories(categories);
    setSearchValue("");

    setLoading(false);
  };

  const onEditSuccess = async () => {
    setLoading(true);
    const response = await menuService.fetchMenuItems(id);
    setUserMenuItems(response);

    if (filterCategoryId > 0) {
      const filteredItems = response.filter(
        (e) => e.categoryId === filterCategoryId
      );

      setFilteredUserMenuItems(filteredItems);
    } else {
      setFilteredUserMenuItems(response);
    }

    setSearchValue("");

    setLoading(false);
  };

  const getCategoryLabel = (category: Category) => {
    let label = category?.title;

    if ((category?.parentId || 0) > 0) {
      const parent = userAllCategories.find(
        (cat) => cat.id === category?.parentId
      );

      if (parent) {
        label = `${category?.title} (${parent?.title})`;
      }
    }

    return label;
  };

  const isParentCategory = (category: Category) => {
    if ((category?.parentId || 0) === 0) {
      const child = userAllCategories.find(
        (cat) => cat.parentId === category?.id
      );

      if (child) {
        return true;
      }
    }

    return false;
  };

  return (
    <Layout>
      <LeftSidebar page="menus" />
      <MenuItemCreateModal
        key="menu-item-create-modal"
        menuId={menuId}
        activeState={[createMenuItem, setCreateMenuItem]}
        onSuccess={onMenuItemCreated}
      />
      <Content style={{ textAlign: "center", padding: "5px" }}>
        <Layout>
          <ContentPageHeader
            pageTitle={
              currentMenuTitle
                ? `${currentMenuTitle} - ${t("title.menuItems").toLowerCase()}`
                : t("title.menuItems")
            }
            buttons={buttons}
          />
          <Skeleton active loading={loading}>
            <Content style={{ padding: 25 }}>
              <Content style={{ textAlign: "left", padding: "5px" }}>
                <Space align="baseline" wrap>
                  {t("label.category") + ":"}{" "}
                  <Select
                    showSearch
                    filterOption={(input, option) => {
                      if (!Array.isArray(option?.children)) {
                        return (
                          (option?.children ?? "")
                            ?.toLowerCase()
                            .indexOf(input?.toLowerCase()) >= 0
                        );
                      } else {
                        return false;
                      }
                    }}
                    loading={loading}
                    value={filterCategoryId}
                    placeholder={t("input.selectCategory")}
                    style={{ width: "200px", marginBottom: 10 }}
                    onChange={(value: number) => {
                      setFilterCategoryId(value);

                      if (value > 0) {
                        const filteredItems = userMenuItems.filter(
                          (e) => e.categoryId === value
                        );

                        setFilteredUserMenuItems(filteredItems);
                        let searchOptions = filteredUserMenuItems.map(
                          (item: MenuItem) => {
                            return {
                              value: item.title,
                              key: `${item.title}-${item.id}`,
                            };
                          }
                        );

                        setSearchOptions(searchOptions);
                        setFilteredSearchOptions(searchOptions);
                        setSearchValue("");
                      } else {
                        setFilteredUserMenuItems(userMenuItems);

                        let searchOptions = userMenuItems.map(
                          (item: MenuItem) => {
                            return {
                              value: item.title,
                              key: `${item.title}-${item.id}`,
                            };
                          }
                        );

                        setSearchOptions(searchOptions);
                        setFilteredSearchOptions(searchOptions);
                        setSearchValue("");
                      }
                    }}
                  >
                    <Option value={0}>{t("label.allCategories")}</Option>
                    {userCategories.map((cat: Category, index) => {
                      return (
                        <Option
                          key={`menu-cats-${cat.id}-${index}`}
                          value={cat.id || 0}
                          disabled={isParentCategory(cat)}
                        >
                          <span
                            style={{
                              marginLeft: (cat.parentId || 0) > 0 ? 10 : 0,
                            }}
                          >
                            {getCategoryLabel(cat)}
                          </span>
                        </Option>
                      );
                    })}
                  </Select>
                  {t("label.search") + ":"}{" "}
                  <AutoComplete
                    value={searchValue}
                    options={filteredSearchOptions}
                    style={{ width: 200 }}
                    onSelect={onSearchSelect}
                    //onSearch={onSearch}
                    onChange={onSearchChange}
                    allowClear={true}
                  />
                </Space>
              </Content>
              <MenusItemsTable
                menuItems={filteredUserMenuItems}
                userCategories={userAllCategories}
                onEditSuccess={onEditSuccess}
                pageSize={defaultPageSize}
                onPaginationChange={setDefaultPageSize}
                currentPage={currentPage}
                onPageChange={setCurrentPage}
              />
            </Content>
          </Skeleton>
        </Layout>
      </Content>
    </Layout>
  );
};
