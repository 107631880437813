export const brandColors = {
  blue: "#4DC5D7",
  orange: "#FF6633",
};

export const backgroundColors = {
  header: "#293847",
  stroke: "#DDDDDD",
  blue: "#E4F6F9",
  grey: "#F9F9F9",
};

export const messagingColors = {
  ctaBlue: "#0072C6",
  success: "#107C10",
  error: "#A80000",
  grey: "#CCCCCC",
};

export const textColors = {
  main: "#333333",
  invertedMain: "#FFFFFF",
  white: "#ffffff",
  subMain: "#666666",
  inactive: "#999999",
};

export const statusBackgroundColors = {
  yellow: "#FFFEE0",
  success: "#DFF6DD",
  warning: "#FFF4CE",
  error: "#FDE7E9",
};
