import { useMediaQuery } from 'react-responsive';
import { buildViewPortQuery, DESKTOP_BREAK_POINT, MOBILE_BREAK_POINT, TABLET_BREAK_POINT } from 'src/core/utils';

type ViewPortKind = 'desktop' | 'tablet' | 'mobile' | 'unsupported';

export const useViewPortQuery = (): ViewPortKind => {
    const isDesktop = useMediaQuery({
        query: `${buildViewPortQuery(DESKTOP_BREAK_POINT)}`,
    });

    const isTablet = useMediaQuery({
        query: `${buildViewPortQuery(TABLET_BREAK_POINT, DESKTOP_BREAK_POINT)}`,
    });

    const isMobile = useMediaQuery({
        query: `${buildViewPortQuery(MOBILE_BREAK_POINT, TABLET_BREAK_POINT)}`,
    });

    if (isDesktop) {
        return 'desktop';
    }

    if (isTablet) {
        return 'tablet';
    }

    if (isMobile) {
        return 'mobile';
    }

    return 'unsupported';
};
