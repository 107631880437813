import { Layout, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { LeftSidebar } from "src/modules/leftSidebar/LeftSidebar";
import { ContentPageHeader } from "src/modules/pageHeader/PageHeader";

const { Content } = Layout;

export const MenuDesignsPage = () => {
  const [loading, setLoading] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      setLoading(false);
    })();
  }, []);

  return (
    <Layout>
      <LeftSidebar page="menu-designs" />
      <Content style={{ textAlign: "center", padding: "5px" }}>
        <Layout>
          <ContentPageHeader pageTitle={t("title.menuDesigns")} buttons={[]} />
          <Skeleton active loading={loading}>
            <Content style={{ padding: 25, textAlign: "left" }}>
              <p>{t("menuDesigns.intro1")}</p>
              <p>{t("menuDesigns.intro2")}</p>
              <p>{t("menuDesigns.intro3")}</p>
              <p>
                <b>- {t("help.phone")}:</b> {t("help.phoneValue")}
              </p>
              <p>
                <b>- {t("help.email")}:</b> {t("help.emailValue")}
              </p>
            </Content>
          </Skeleton>
        </Layout>
      </Content>
    </Layout>
  );
};
