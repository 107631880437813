import { Button, Layout, Select, Skeleton, Space } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { categoryService } from "src/core/services/categoryService";
import { menuService } from "src/core/services/menuService";
import { getQueryParamValue } from "src/core/utils/utils";
import { CategoriesTable } from "src/modules/categoriesTable/CategoriesTable";
import { CategoryCreateModal } from "src/modules/categoryCreateModal/CategoryCreateModal";
import { LeftSidebar } from "src/modules/leftSidebar/LeftSidebar";
import { ContentPageHeader } from "src/modules/pageHeader/PageHeader";
import { Category, Menu } from "src/types/types";

const { Content } = Layout;
const { Option, OptGroup } = Select;

export const UserCategoriesPage = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [createCategory, setCreateCategory] = useState(false);
  const [userCategories, setUserCategories] = useState<Category[]>([]);

  const [userMenus, setUserMenus] = useState<Menu[]>([]);
  const [resetPage, setResetPage] = useState<boolean>(false);
  const [filterMenuId, setFilterMenuId] = useState<number>(0);
  const [preselectedMenuId, setPreselectedMenuId] = useState<number>(0);
  const [filteredCategories, setFilteredCategories] = useState<Category[]>([]);

  const [defaultPageSize, setDefaultPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const response = await categoryService.fetchUserCategories();
      setUserCategories(response);
      setFilteredCategories(response);

      const userMenus = await menuService.fetchUserMenus();
      setUserMenus(userMenus);

      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (filterMenuId !== 0) {
        const response = await categoryService.fetchUserCategoriesByMenuId(
          filterMenuId
        );
        setFilteredCategories(response);
      } else {
        setFilteredCategories(userCategories);
      }

      if (resetPage) {
        setCurrentPage(1);
        setResetPage(false);
      }
    })();
  }, [filterMenuId, resetPage, userCategories]);

  useEffect(() => {
    (async () => {
      const preselectedMenuId = getQueryParamValue("menuId");

      if (preselectedMenuId) {
        setPreselectedMenuId(preselectedMenuId as unknown as number);
      }
    })();
  }, []);

  useEffect(() => {
    if (preselectedMenuId > 0) {
      setFilterMenuId(preselectedMenuId * 1);
      setResetPage(true);
    }
  }, [preselectedMenuId]);

  var buttons = [];

  if (userMenus.length > 0) {
    buttons.push(
      <Button
        onClick={() => {
          setCreateCategory(true);
        }}
        key="add-button"
        type="primary"
      >
        {t("button.createCategory")}
      </Button>
    );
  }

  const onCategoryCreated = async () => {
    setCreateCategory(false);
    setLoading(true);

    const response = await categoryService.fetchUserCategories();
    setUserCategories(response);
    setFilteredCategories(response);

    setLoading(false);
  };

  const onEditSuccess = async () => {
    setLoading(true);
    const response = await categoryService.fetchUserCategories();
    setUserCategories(response);
    setFilteredCategories(response);

    setLoading(false);
  };

  return (
    <Layout>
      <LeftSidebar page="categories" />
      <CategoryCreateModal
        usermenus={userMenus}
        activeState={[createCategory, setCreateCategory]}
        filteredMenuId={filterMenuId}
        onSuccess={onCategoryCreated}
      />
      <Content style={{ textAlign: "center", padding: "5px" }}>
        <Layout>
          <ContentPageHeader
            pageTitle={t("title.menuCategories")}
            buttons={buttons}
          />
          <Skeleton active loading={loading}>
            {userMenus.length > 0 && (
              <Content style={{ padding: 25 }}>
                <Content style={{ textAlign: "left", padding: "5px" }}>
                  <Space align="baseline" wrap>
                    {t("label.menuFilter") + ":"}{" "}
                    <Select
                      showSearch
                      filterOption={(input, option) => {
                        if (!Array.isArray(option?.children)) {
                          return (
                            (option?.children ?? "")
                              ?.toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          );
                        } else {
                          return false;
                        }
                      }}
                      loading={loading}
                      value={filterMenuId}
                      placeholder={t("input.selectMenu")}
                      style={{ width: "245px", marginBottom: 10 }}
                      onChange={(value: number) => {
                        setFilterMenuId(value);
                        setResetPage(true);
                      }}
                    >
                      <Option value={0}>{t("label.allMenus")}</Option>
                      <OptGroup label={t("label.menus")}>
                        {userMenus.map((menu: Menu, index) => {
                          return (
                            <Option
                              key={`option-menu-${index}`}
                              value={menu.id || 0}
                            >
                              {(menu.description || "") !== ""
                                ? `${menu.title} (${menu.description})`
                                : menu.title}
                            </Option>
                          );
                        })}
                      </OptGroup>
                    </Select>
                  </Space>
                </Content>
                <CategoriesTable
                  categories={filteredCategories}
                  onEditSuccess={onEditSuccess}
                  pageSize={defaultPageSize}
                  onPaginationChange={setDefaultPageSize}
                  currentPage={currentPage}
                  onPageChange={(pageNumber: number) => {
                    setCurrentPage(pageNumber);
                  }}
                  userMenus={userMenus}
                />
              </Content>
            )}
            {userMenus.length === 0 && (
              <Content style={{ textAlign: "center", padding: "5px" }}>
                <Layout>
                  <Skeleton active loading={loading}>
                    <Content style={{ padding: 25, textAlign: "left" }}>
                      <p>{t("message.createMenuFirst")}</p>
                      <Button onClick={() => history.push("/admin/menus")}>
                        {t("message.goToMenus")}
                      </Button>
                    </Content>
                  </Skeleton>
                </Layout>
              </Content>
            )}
          </Skeleton>
        </Layout>
      </Content>
    </Layout>
  );
};
