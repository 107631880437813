import { createHashHistory } from "history";
import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "src/App.css";
import { AdminPage } from "src/pages/adminPage/AdminPage";
import { UserCategoriesPage } from "src/pages/adminPage/categoriesPage/UserCategoriesPage";
import { HelpPage } from "src/pages/adminPage/helpPage/HelpPage";
import { MenuDesignsPage } from "src/pages/adminPage/menuDesignsPage/MenuDesignsPage";
import { UserMenuItemsPage } from "src/pages/adminPage/menuItemsPage/UserMenuItemsPage";
import { UserMenusPage } from "src/pages/adminPage/menusPage/UserMenusPage";
import { UserMultiMenusPage } from "src/pages/adminPage/multiMenusPage/UserMultiMenusPage";
import { UserOrdersPage } from "src/pages/adminPage/ordersPage/UserOrdersPage";
import { ForgetPasswordPage } from "src/pages/forgetPasswordPage/ForgetPasswordPage";
import { HomePage } from "src/pages/homePage/HomePage";
import { LoginPage } from "src/pages/loginPage/LoginPage";
import { MenuPage } from "src/pages/menuPage/MenuPage";
import { OrderPage } from "src/pages/orderPage/OrderPage";
import { PdfMenuPage } from "src/pages/pdfMenuPage/PdfMenuPage";
import { RegistrationPage } from "src/pages/registrationPage/RegistrationPage";

export const history = createHashHistory();

export class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/" component={() => <HomePage />} />
          <Route path="/menus/:identifier" component={() => <MenuPage />} />
          <Route path="/m/:identifier" component={() => <MenuPage />} />
          <Route path="/pdf" component={() => <PdfMenuPage />} />
          <Route path="/o/:order" component={() => <OrderPage />} />
          <Route path="/signin" component={() => <LoginPage />} />
          <Route path="/register" component={() => <RegistrationPage />} />
          <Route path="/forget" component={() => <ForgetPasswordPage />} />
          <Route exact path="/admin" component={() => <AdminPage />} />
          <Route
            exact
            path="/admin/menus"
            component={() => <UserMenusPage />}
          />
          <Route
            exact
            path="/admin/multi-menus"
            component={() => <UserMultiMenusPage />}
          />
          <Route
            exact
            path="/admin/menus/:id/items"
            component={() => <UserMenuItemsPage />}
          />
          <Route
            exact
            path="/admin/categories"
            component={() => <UserCategoriesPage />}
          />
          <Route
            exact
            path="/admin/menu-designs"
            component={() => <MenuDesignsPage />}
          />
          <Route
            exact
            path="/admin/orders"
            component={() => <UserOrdersPage />}
          />
          <Route exact path="/admin/help" component={() => <HelpPage />} />
          <HomePage />
        </Switch>
      </Router>
    );
  }
}
