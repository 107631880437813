import { messagingColors } from "src/core/style/colors";

export type SpaceSize = "mini" | "small" | "medium" | "large" | "none" | number;

export const DESKTOP_BREAK_POINT = 1200;

export const TABLET_BREAK_POINT = 768;

export const MOBILE_BREAK_POINT = 320;

export const buildViewPortQuery = (minWidth: number, maxWidth?: number) => {
  const queryItems = [`(min-width: ${minWidth}px)`];

  if (maxWidth) {
    queryItems.push(`(max-width: ${maxWidth - 1}px)`);
  }

  return queryItems.join(" and ");
};

// mowan: may add a second argument for the view port size
export const getSpace = (size?: SpaceSize) => {
  if (typeof size === "number") {
    return size;
  }

  switch (size) {
    case "none":
      return 0;
    case "large":
      return 24;
    case "medium":
      return 16;
    case "mini":
      return 4;
    case "small":
    default:
      return 8;
  }
};

export const concateClassNames = (
  ...classNames: (string | undefined | null)[]
) => {
  return classNames.filter((name) => !!name).join(" ");
};

export const SuccessStyle = {
  borderLeft: `4px solid ${messagingColors.success}`,
} as React.CSSProperties;

export const ErrorStyle = {
  borderLeft: `4px solid ${messagingColors.error}`,
} as React.CSSProperties;
