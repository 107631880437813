import { BackTop, Layout, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { menuDesignService } from "src/core/services/menuDesignService";
import { PageFooter } from "src/modules/pageFooter/PageFooter";
import { MenuContent } from "src/pages/menuPage/components/MenuContent";
import { MenuDesign } from "src/types/types";

export const MenuPage = () => {
  const [loading, setLoading] = useState(true);
  const [menuIdentifier, setMenuIdentifier] = useState<string>("");
  const [menuDesign, setMenuDesign] = useState<MenuDesign>();

  let { identifier }: any = useParams();

  useEffect(() => {
    (async () => {
      setMenuIdentifier(identifier);

      const response = await menuDesignService.getMenuDesign(identifier);
      setMenuDesign(response);

      setLoading(false);
    })();
  }, [identifier]);

  return (
    <Layout
      className={menuDesign?.backgroundClass || "menuBackground-0"}
      style={{
        textAlign: "center",
        minHeight: "100%",
      }}
    >
      <Skeleton active loading={loading}>
        <BackTop />
        <MenuContent menuDesign={menuDesign} menuIdentifier={menuIdentifier} />
      </Skeleton>
      <PageFooter />
    </Layout>
  );
};
