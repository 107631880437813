import { Layout, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PageFooter } from "src/modules/pageFooter/PageFooter";
import { OrderContent } from "src/pages/orderPage/components/OrderContent";

export const OrderPage = () => {
  const [loading, setLoading] = useState(true);
  const [orderNumber, setOrderNumber] = useState<string>("");

  let { order }: any = useParams();

  useEffect(() => {
    (async () => {
      setOrderNumber(order);

      setLoading(false);
    })();
  }, [order]);

  return (
    <Layout
      className={"menuBackground-11"}
      style={{
        textAlign: "center",
        minHeight: "100%",
      }}
    >
      <Skeleton active loading={loading}>
        <OrderContent orderNumber={orderNumber} />
      </Skeleton>
      <PageFooter />
    </Layout>
  );
};
