import { EditOutlined } from "@ant-design/icons";
import { Alert, Button, Layout, notification, Skeleton, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { signOut } from "src/authProvider";
import { userService } from "src/core/services/userService";
import { getQueryParamValue } from "src/core/utils/utils";
import { LeftSidebar } from "src/modules/leftSidebar/LeftSidebar";
import { ContentPageHeader } from "src/modules/pageHeader/PageHeader";
import { PaymentOrderModal } from "src/modules/paymentOrderModal/PaymentOrderModal";
import { User } from "src/types/types";

const { Content } = Layout;

export const AdminPage = () => {
  const [loading, setLoading] = useState(true);
  const [createPaymentOrder, setCreatePaymentOrder] = useState(false);
  const [currentUser, setCurrentUser] = useState<User>({
    name: "",
    email: "",
    plan: "",
    planActive: false,
  });
  const [firstTimeUse, setFirstTimeUse] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const user = await userService.fetchUser();

      setCurrentUser(user);

      setFirstTimeUse((getQueryParamValue("firstTime") ?? false) as boolean);
      setLoading(false);
    })();
  }, []);

  var buttons = [];

  buttons.push(
    <Button
      onClick={() => {
        signOut();
      }}
      key="sign-out-button"
      type="primary"
    >
      {t("button.signOut")}
    </Button>
  );

  return (
    <Layout>
      <LeftSidebar page="admin" />
      <PaymentOrderModal
        key="multi-menu-create-modal"
        activeState={[createPaymentOrder, setCreatePaymentOrder]}
        user={currentUser}
      />
      <Content style={{ textAlign: "center", padding: "5px" }}>
        <ContentPageHeader
          pageTitle={t("adminHome.header")}
          buttons={buttons}
        />
        <Skeleton active loading={loading}>
          <Content style={{ padding: 25, textAlign: "left" }}>
            {firstTimeUse ? (
              <Alert
                message={t("adminHome.afterRegistrationIntro")}
                closeText={`${t("button.createMenu")} ->`}
                afterClose={() => {
                  history.push("/admin/menus");
                }}
                description={
                  <React.Fragment>
                    <p style={{ marginBottom: 0 }}>
                      {t("adminHome.afterRegistrationIntroDescription1")}
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      {t("adminHome.afterRegistrationIntroDescription2")}
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      {t("adminHome.afterRegistrationIntroDescription3")}
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      {t("adminHome.afterRegistrationIntroDescription4")}
                    </p>
                  </React.Fragment>
                }
                type="success"
                style={{ marginBottom: "20px", maxWidth: "740px" }}
                closable
              />
            ) : null}
            <Space wrap>
              <Content style={{ maxWidth: 340, paddingRight: 40, top: 0 }}>
                <h2>
                  {t("adminHome.hello")}, {currentUser?.name}
                </h2>
                <p>{t("adminHome.intro")}</p>
                <p>{t("adminHome.subIntro")}</p>
                <p>
                  <b>{t("adminHome.username")}:</b> {currentUser?.email}
                </p>
                <p>
                  <b>{t("adminHome.password")}:</b> ************{" "}
                  <EditOutlined
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      notification.open({
                        message: t("label.info"),
                        description: t("message.passwordChangeMessage"),
                        onClick: () => {},
                      });
                    }}
                  />
                </p>
              </Content>
              <Content style={{ maxWidth: 340 }}>
                <p>
                  <b>{t("adminHome.userPlan")}:</b> {t("plan.basic")}
                  <EditOutlined
                    style={{ marginLeft: 10 }}
                    onClick={() => {
                      notification.open({
                        message: t("label.info"),
                        description: t("message.planChangeMessage"),
                        onClick: () => {},
                      });
                    }}
                  />
                </p>
                <p>
                  <b>{t("adminHome.state")}:</b>{" "}
                  {true || currentUser?.planActive
                    ? t("plan.stateActive")
                    : t("plan.stateInactive")}{" "}
                  {false && (
                    <EditOutlined
                      style={{ marginLeft: 10 }}
                      onClick={() => {
                        notification.open({
                          message: t("label.info"),
                          description: t("message.stateChangeMessage"),
                          onClick: () => {},
                        });
                      }}
                    />
                  )}
                </p>
                {false && (
                  <React.Fragment>
                    <p>
                      <b>{t("adminHome.planExpires")}:</b>{" "}
                      {currentUser?.planExpires
                        ? currentUser?.planExpires
                        : "-"}
                    </p>
                    <Content
                      style={{
                        marginBottom: 20,
                        textAlign: "right",
                        maxWidth: 320,
                      }}
                    >
                      <Button
                        type="primary"
                        style={{ width: 100 }}
                        onClick={() => {
                          setCreatePaymentOrder(true);
                        }}
                        disabled={false}
                      >
                        {currentUser?.planExpires
                          ? t("button.extend")
                          : t("button.order")}
                      </Button>
                    </Content>
                    <p>
                      <b>{t("adminHome.important")}:</b>{" "}
                      {t("adminHome.importantNote")}
                    </p>
                  </React.Fragment>
                )}
              </Content>
            </Space>
          </Content>
        </Skeleton>
      </Content>
    </Layout>
  );
};
