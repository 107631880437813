import { Button, Form, Input, Layout, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { setAccessToken } from "src/authProvider";
import { Spinner } from "src/core/components/Spinner";
import { authService } from "src/core/services/authService";
import { textColors } from "src/core/style/colors";
import { PageFooter } from "src/modules/pageFooter/PageFooter";

const { Header, Content } = Layout;

export const LoginPage = () => {
  const [submitted, setSubmitted] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {})();
  }, []);

  const onFinish = async (values: any) => {
    const response = await authService.signIn(
      values["username"],
      values["password"]
    );

    if (!response.success) {
      setLoginError(true);
      setSubmitted(false);
    } else {
      setAccessToken(response.token);
      history.push("/admin");
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    setSubmitted(false);
  };

  return (
    <Layout>
      <Header
        style={{
          background: "white",
          height: 80,
          textAlign: "center",
          lineHeight: "20px",
          padding: "25px",
        }}
      >
        <h1>{t("home.header")}</h1>
      </Header>
      <Content
        style={{
          textAlign: "center",
          padding: "50px",
          maxWidth: "600px",
          margin: "0 auto",
        }}
      >
        <Content>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{}}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            onSubmitCapture={() => {
              setSubmitted(true);
            }}
          >
            <Form.Item
              label={t("input.email")}
              name="username"
              rules={[{ required: true, message: t("warning.enterUsername") }]}
            >
              <Input
                type="email"
                onChange={() => {
                  setLoginError(false);
                }}
              />
            </Form.Item>

            <Form.Item
              label={t("input.password")}
              name="password"
              rules={[{ required: true, message: t("warning.enterPassword") }]}
            >
              <Input.Password
                onChange={() => {
                  setLoginError(false);
                }}
              />
            </Form.Item>

            {loginError && (
              <div style={{ paddingBottom: "10px", color: "red" }}>
                {t("error.signIn")}
              </div>
            )}

            <Form.Item
              wrapperCol={{ offset: 0, span: 16 }}
              style={{ flexDirection: "row-reverse" }}
            >
              <Space>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ width: "122px" }}
                  onClick={() => {
                    setLoginError(false);
                  }}
                >
                  {submitted ? (
                    <Spinner size="small" color={textColors.white} />
                  ) : (
                    t("button.signIn")
                  )}
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Content>
        <Content style={{ marginTop: 40 }}>
          <Button
            type="link"
            style={{ width: "142px" }}
            onClick={() => {
              history.push("/forget");
            }}
          >
            {t("button.forgotPassword")}
          </Button>
          |
          <Button
            type="link"
            style={{ width: "102px" }}
            onClick={() => {
              history.push("/register");
            }}
          >
            {t("button.register")}
          </Button>
        </Content>
      </Content>
      <PageFooter />
    </Layout>
  );
};
