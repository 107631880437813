import { API_BASE_URL } from "src/constants";
import { get } from "src/core/utils/api";
import { User } from "src/types/types";

const createUserService = () => {
  const fetchUser = async (): Promise<User> => {
    const response = await get<User>(API_BASE_URL + `/users/me`);

    return response;
  };

  return {
    fetchUser,
  };
};
export const userService = createUserService();
