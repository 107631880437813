import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AllPagesViewer from "./components/AllPagesViewer";

export const PdfMenuPage = () => {
  const [loading, setLoading] = useState(true);
  const [pdfLink, setPdfLink] = useState("");

  const { search } = useLocation();

  useEffect(() => {
    (async () => {
      if (search) {
        const query = new URLSearchParams(search);

        setPdfLink(query.get("l") ?? "");
        setLoading(false);
      }
    })();
  }, [search]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {!loading && <AllPagesViewer pdf={pdfLink} />}
    </div>
  );
};
