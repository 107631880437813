import { API_BASE_URL } from "src/constants";
import { categoryService } from "src/core/services/categoryService";
import { destroy, get, getUnauthorized, post, put } from "src/core/utils/api";
import {
  Category,
  Menu,
  MenuData,
  MenuItem,
  MenuItemsCategory,
  MenuItemsGrouped,
} from "src/types/types";

const createMenuService = () => {
  const fetchUserMenus = async (): Promise<Menu[]> => {
    const response = await get<Menu[]>(API_BASE_URL + `/menus`);

    return response;
  };

  const fetchMenu = async (menuId: string): Promise<Menu> => {
    const response = await get<Menu>(API_BASE_URL + `/menus/${menuId}`);

    return response;
  };

  const createMenu = async (menu: Menu): Promise<Menu> => {
    const response = await post<Menu>(API_BASE_URL + `/menus`, menu);

    return response;
  };

  const updateMenu = async (menu: Menu): Promise<Menu> => {
    const response = await put<Menu>(API_BASE_URL + `/menus/${menu.id}`, menu);

    return response;
  };

  const deleteMenu = async (id: number) => {
    await destroy(API_BASE_URL + `/menus/${id}`);
  };

  const createMenuItem = async (item: MenuItem): Promise<MenuItem> => {
    const response = await post<MenuItem>(
      API_BASE_URL + `/menus/${item.menuId}/items`,
      item
    );

    return response;
  };

  const updateMenuItem = async (item: MenuItem): Promise<MenuItem> => {
    const response = await put<MenuItem>(
      API_BASE_URL + `/menus/${item.menuId}/items/${item.id}`,
      {
        id: item.id,
        title: item.title,
        description: item.description,
        price: item.price,
        secondPrice: item.secondPrice,
        categoryId: item.categoryId,
        orderNumber: item.orderNumber,
        hidden: item.hidden,
        spicy: item.spicy,
        vegetarian: item.vegetarian,
        vegan: item.vegan,
        new: item.new,
        top: item.top,
      } as MenuItem
    );

    return response;
  };

  const deleteMenuItem = async (item: MenuItem) => {
    await destroy(API_BASE_URL + `/menus/${item.menuId}/items/${item.id}`);
  };

  const fetchMenuItems = async (menuId: string): Promise<MenuItem[]> => {
    const response = await get<MenuItem[]>(
      API_BASE_URL + `/menus/${menuId}/items`
    );

    return response;
  };

  const fetchMenuItemsGrouped = async (
    identifier: string
  ): Promise<MenuItemsGrouped> => {
    let result = {
      title: "",
      description: "",
      items: [],
      sections: [],
      menuFound: false,
    } as MenuItemsGrouped;

    const menuData = await getUnauthorized<MenuData>(
      API_BASE_URL + `/menus/${identifier}/data`
    );

    if (menuData) {
      result = {
        id: menuData.id,
        title: menuData.title,
        description: menuData.description,
        headerLogo: menuData.headerLogo,
        showSidebar: menuData.showSidebar,
        ordersEnabled: menuData.ordersEnabled,
        items: [],
        sections: [],
        pdfLink: menuData.pdfLink,
        menuFound: true,
      };

      if (menuData.items && menuData.items.length > 0) {
        const categories = await categoryService.fetchUserCategoriesByMenu(
          identifier
        );

        let parentCategories: Category[] = [];

        menuData.items.forEach((menuItem: MenuItem) => {
          const group = result.items.find(
            (f) => f.categoryId === menuItem.categoryId
          );

          const category = categories.find((c) => c.id === menuItem.categoryId);
          let parentCategory: any = undefined;

          // if category has parent category
          if ((category?.parentId || 0) > 0) {
            parentCategory = categories.find(
              (c) => c.id === category?.parentId
            );

            if (parentCategory) {
              const index = categories.indexOf(parentCategory);

              if (index > -1) {
                parentCategories.push(parentCategory);
                categories.splice(index, 1);
              }
            }
          }

          // if menu group exists
          if (group) {
            group.menuItems.push(menuItem);
          } else {
            let orderNumber = parentCategory
              ? (parentCategory as Category).orderNumber
              : category?.orderNumber;

            if (!parentCategory) {
              const removedParentCategory = parentCategories.find(
                (c) => c.id === category?.parentId
              );

              if (removedParentCategory) {
                orderNumber = removedParentCategory.orderNumber;
              }
            }

            result.items.push({
              categoryId: menuItem.categoryId,
              title: category?.title,
              description: category?.description,
              orderNumber: orderNumber,
              secondOrderNumber: category?.orderNumber,
              menuItems: [menuItem],
              parentCategory: parentCategory,
              hasParent: (category?.parentId || 0) > 0,
              twoColumns: category?.twoColumns,
              bottomNote: category?.bottomNote,
              hideHeader: category?.hideHeader,
              orderByPrice: category?.orderByPrice,
            });
          }
        });

        result.items = result.items.sort((el1, el2) => {
          return (el1.secondOrderNumber || 0) < (el2.secondOrderNumber || 0)
            ? -1
            : 1;
        });

        result.items = result.items.sort((el1, el2) => {
          return (el1.orderNumber || 0) < (el2.orderNumber || 0) ? -1 : 1;
        });

        result.items.forEach((categoryGroup: MenuItemsCategory) => {
          if (!categoryGroup.orderByPrice) {
            categoryGroup.menuItems = categoryGroup.menuItems.sort(
              (el1, el2) => {
                return (el1.orderNumber || 0) < (el2.orderNumber || 0) ? -1 : 1;
              }
            );
          } else {
            categoryGroup.menuItems = categoryGroup.menuItems.sort(
              (el1, el2) => {
                return (el1.price || 0) < (el2.price || 0) ? -1 : 1;
              }
            );
          }
        });
      }

      if (menuData.sections && menuData.sections.length > 0) {
        result.sections = menuData.sections;
      }
    }

    return result;
  };

  return {
    fetchMenu,
    fetchMenuItems,
    fetchMenuItemsGrouped,
    fetchUserMenus,
    createMenu,
    createMenuItem,
    updateMenuItem,
    deleteMenuItem,
    updateMenu,
    deleteMenu,
  };
};
export const menuService = createMenuService();
