import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { DEFAULT_LANGUAGE } from "src/constants";
import translationEN from "src/languages/translationEN.json";
import translationLT from "src/languages/translationLT.json";

const fallbackLng = [DEFAULT_LANGUAGE];
const availableLanguages = ["en", "lt"];

const resources = {
  en: {
    translation: translationEN,
  },
  lt: {
    translation: translationLT,
  },
};

i18n
  .use(Backend) // load translations using http (default                                               public/assets/locals/en/translations)
  .use(LanguageDetector) // detect user language
  .use(initReactI18next) // pass the i18n instance to react-i18next.
  .init({
    resources,
    fallbackLng, // fallback language is english.

    detection: {
      checkWhitelist: true, // options for language detection
    },

    debug: false,

    whitelist: availableLanguages,

    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
  });

i18n.changeLanguage(DEFAULT_LANGUAGE);

export default i18n;

export const getActiveLanguage = () => {
  return (
    i18n.language ||
    (typeof window !== "undefined" && window.localStorage.i18nextLng) ||
    DEFAULT_LANGUAGE
  );
};

export const changeLanguage = (lng) => {
  i18n.changeLanguage(lng);
};
