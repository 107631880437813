import { ReloadOutlined } from "@ant-design/icons";
import { Button, Col, Image, Layout, Row, Skeleton, Space } from "antd";
import React, { CSSProperties, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ORDER_BASE_URL } from "src/constants";
import { Spinner } from "src/core/components/Spinner";
import { orderService } from "src/core/services/orderService";
import { paymentService } from "src/core/services/paymentService";
import { textColors } from "src/core/style/colors";
import { getQueryParamValue } from "src/core/utils/utils";
import { Order, OrderItem } from "src/types/types";

const { Header, Content } = Layout;

type Props = {
  orderNumber: string;
};

const headerCss: CSSProperties = {
  minHeight: 80,
  textAlign: "center",
  lineHeight: "26px",
  paddingTop: "15px",
  paddingBottom: "5px",
};

export const OrderContent = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [orderNotFound, setOrderNotFound] = useState(false);
  const [refreshing, setRefreshing] = useState(true);
  const [paymentInitiating, setPaymentInitiating] = useState(false);

  const { orderNumber } = props;

  const [currentOrder, setCurrentOrder] = useState<Order>();
  const [currentOrderItems, setCurrentOrderItems] = useState<OrderItem[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const order = await orderService.getPublicOrder(orderNumber);

      if (order) {
        setCurrentOrder(order);

        const orderItems = await orderService.getPublicOrderItems(orderNumber);
        setCurrentOrderItems(orderItems);
      } else {
        setOrderNotFound(true);
      }

      setLoading(false);
    })();
  }, [orderNumber]);

  useEffect(() => {
    (async () => {
      if (!loading) {
        setRefreshing(false);

        const paymentToken = getQueryParamValue("payment_token");

        if (paymentToken) {
          await paymentService.processPaymentToken(paymentToken);

          const order = await orderService.getPublicOrder(orderNumber);

          setCurrentOrder(order);
        }
      }
    })();
  }, [loading, orderNumber]);

  const refreshOrderInfo = async () => {
    setRefreshing(true);

    const order = await orderService.getPublicOrder(orderNumber);
    setCurrentOrder(order);

    await new Promise((r) => setTimeout(r, 1000));

    setRefreshing(false);
  };

  const initiateOrderPayment = async () => {
    setPaymentInitiating(true);

    if (currentOrder) {
      const paymentLink = await paymentService.generateOrderPaymentLink(
        currentOrder
      );

      if (paymentLink) {
        window.location.href = paymentLink;

        // redirect lag
        await new Promise((r) => setTimeout(r, 1000));
      }
    }

    setPaymentInitiating(false);
  };

  const getIntroMessage = (): string => {
    let result = currentOrder?.paymentsEnabled
      ? t("message.orderPageWithPaymentIntro")
      : t("message.orderPageIntro");

    if (
      currentOrder?.status === "paid" ||
      currentOrder?.status === "paidAndAccepted"
    ) {
      result = t("message.orderPagePaidIntro");
    }

    if (currentOrder?.status === "completed") {
      result = t("message.thankYouForYourOrder");
    }

    return result;
  };

  const showOrderStatusReloadIcon =
    currentOrder?.status === "initializing" ||
    currentOrder?.status === "started" ||
    currentOrder?.status === "accepted" ||
    currentOrder?.status === "paid";

  const showPayButton =
    currentOrder?.status === "initializing" ||
    currentOrder?.status === "started" ||
    currentOrder?.status === "accepted";

  return (
    <Skeleton active loading={loading}>
      {orderNotFound && (
        <React.Fragment>
          <Header style={{ ...headerCss, minHeight: "95vh" }}>
            <Row>
              <Col span={20} offset={2}>
                <Content>
                  <h1
                    style={{ marginTop: 15, fontSize: 22 }}
                    className="menu-title"
                  >
                    {`${t("message.orderNotFound")}`}
                  </h1>
                  <h4 style={{ height: 35, lineHeight: "18px" }}>
                    {`${t("message.orderNotFoundDescription")}`}
                  </h4>
                </Content>
              </Col>
            </Row>
          </Header>
        </React.Fragment>
      )}
      {currentOrder && (
        <React.Fragment>
          <Header style={headerCss}>
            <Row>
              <Col span={20} offset={2}>
                <Content>
                  <h1
                    style={{ marginTop: 15, fontSize: 24 }}
                    className="menu-title"
                  >
                    {`${t("label.order")}: ${currentOrder?.orderNumber}`}
                  </h1>
                </Content>
              </Col>
            </Row>
          </Header>
          <Content
            style={{
              margin: "0 auto",
              width: "100%",
              paddingTop: "5px",
            }}
          >
            <Row justify={"center"}>
              <Col span={24}>
                <Space direction="vertical">
                  <h3 style={{ fontSize: 19 }}>
                    {refreshing ? (
                      <Spinner size="normal" color={textColors.main} />
                    ) : (
                      <React.Fragment>
                        <b>{t(t("label.orderStatus"))}:</b>{" "}
                        {`${t(`orderStatus.${currentOrder?.status}`)}`}
                        {currentOrder?.status !== ""}
                        {showOrderStatusReloadIcon && (
                          <ReloadOutlined
                            style={{
                              marginLeft: 15,
                              backgroundColor: "white",
                              padding: 8,
                              borderRadius: "25px",
                            }}
                            onClick={async () => {
                              await refreshOrderInfo();
                            }}
                          />
                        )}
                      </React.Fragment>
                    )}
                  </h3>
                  <h3 style={{ paddingLeft: "25px", paddingRight: "25px" }}>
                    {getIntroMessage()}
                  </h3>
                  <Content style={{ marginBottom: "10px" }}>
                    <Image
                      src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${ORDER_BASE_URL}/${currentOrder?.orderNumber}`}
                      style={{ maxHeight: 150 }}
                      preview={false}
                    />
                  </Content>
                </Space>
                <hr />
                <Content style={{ marginTop: "25px" }}>
                  <Space direction="vertical">
                    {currentOrderItems?.map((cartItem, index) => {
                      return (
                        <React.Fragment key={`cart-i-i-${index}`}>
                          <Space
                            style={{
                              borderBottom: "1px solid",
                              paddingBottom: "10px",
                              paddingTop: "5px",
                            }}
                          >
                            <Space
                              style={{
                                fontWeight: 600,
                                width: "170px",
                                textAlign: "left",
                              }}
                            >
                              {cartItem.menuItemTitle}
                            </Space>{" "}
                            |{" "}
                            <Space
                              style={{ width: "10px" }}
                            >{`x${cartItem.quantity}`}</Space>{" "}
                            |<Space>{`${cartItem.price}`} €</Space>
                          </Space>
                        </React.Fragment>
                      );
                    })}
                    <Space style={{ marginTop: 20 }}>
                      <b>{t("label.total")}</b>
                      {currentOrder?.total}
                    </Space>
                    {currentOrder?.paymentsEnabled && showPayButton && (
                      <Space>
                        <Button
                          style={{ width: 200, marginTop: 15 }}
                          onClick={() => {
                            initiateOrderPayment();
                          }}
                        >
                          {paymentInitiating ? (
                            <Spinner size="small" color={textColors.main} />
                          ) : (
                            t("button.pay")
                          )}
                        </Button>
                      </Space>
                    )}
                    {currentOrder?.additionalDetails && (
                      <Space style={{ marginTop: 20 }}>
                        <b>{t("label.note")}: </b>
                        {currentOrder?.additionalDetails}
                      </Space>
                    )}
                  </Space>
                </Content>
              </Col>
            </Row>
          </Content>
        </React.Fragment>
      )}
    </Skeleton>
  );
};
