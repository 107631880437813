import { API_BASE_URL } from "src/constants";
import { destroy, get, post, put } from "src/core/utils/api";
import { Menu, MultiMenu, MultiMenuSection } from "src/types/types";

const createMultiMenuService = () => {
  const fetchUserMultiMenus = async (): Promise<MultiMenu[]> => {
    const response = await get<MultiMenu[]>(API_BASE_URL + `/multimenus`);

    return response;
  };

  const fetchMultiMenu = async (menuId: string): Promise<Menu> => {
    const response = await get<Menu>(API_BASE_URL + `/multimenus/${menuId}`);

    return response;
  };

  const createMultiMenu = async (menu: MultiMenu): Promise<MultiMenu> => {
    const response = await post<MultiMenu>(API_BASE_URL + `/multimenus`, menu);

    return response;
  };

  const updateMultiMenu = async (menu: Menu): Promise<MultiMenu> => {
    const response = await put<MultiMenu>(
      API_BASE_URL + `/multimenus/${menu.id}`,
      menu
    );

    return response;
  };

  const deleteMultiMenu = async (id: number) => {
    const response = await destroy(API_BASE_URL + `/multimenus/${id}`);
  };

  const createMultiMenuSection = async (
    item: MultiMenuSection
  ): Promise<MultiMenuSection> => {
    const response = await post<MultiMenuSection>(
      API_BASE_URL + `/multimenus/${item.multiMenuId}/sections`,
      item
    );

    return response;
  };

  const updateMultiMenuSection = async (
    item: MultiMenuSection
  ): Promise<MultiMenuSection> => {
    const response = await put<MultiMenuSection>(
      API_BASE_URL + `/multimenus/${item.multiMenuId}/sections/${item.id}`,
      item
    );

    return response;
  };

  const deleteMultiMenuSection = async (item: MultiMenuSection) => {
    await destroy(
      API_BASE_URL + `/multimenus/${item.multiMenuId}/sections/${item.id}`
    );
  };

  const fetchMultiMenuSections = async (
    menuId: number
  ): Promise<MultiMenuSection[]> => {
    const response = await get<MultiMenuSection[]>(
      API_BASE_URL + `/multimenus/${menuId}/sections`
    );

    return response;
  };

  return {
    fetchUserMultiMenus,
    createMultiMenu,
    updateMultiMenu,
    deleteMultiMenu,
    fetchMultiMenu,
    fetchMultiMenuSections,
    deleteMultiMenuSection,
    createMultiMenuSection,
    updateMultiMenuSection,
  };
};
export const multiMenuService = createMultiMenuService();
