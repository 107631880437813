import {
  Button,
  Input,
  InputNumber,
  Layout,
  Modal,
  notification,
  Select,
  Space,
} from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "src/core/components/Spinner";
import { categoryService } from "src/core/services/categoryService";
import { textColors } from "src/core/style/colors";
import { Category, Menu } from "src/types/types";

type Props = {
  usermenus: Menu[];
  activeState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  filteredMenuId: number;
  onSuccess?: () => void;
};

const { Option, OptGroup } = Select;
const { TextArea } = Input;

export const CategoryCreateModal = (props: Props) => {
  const {
    activeState: [active, setActive],
    usermenus,
    filteredMenuId,
  } = props;

  const [loading, setLoading] = useState(true);
  const [allCategories, setAllCategories] = useState<Category[]>();
  const [menuCategories, setMenuCategories] = useState<Category[]>();

  const [newCategory, setNewCategory] = useState<Category>({
    title: "",
    description: "",
    orderNumber: 0,
    twoColumns: false,
    bottomNote: "",
    hideHeader: false,
    menuId: filteredMenuId === 0 ? undefined : filteredMenuId,
    hidden: false,
    orderByPrice: false,
  });

  const [titleRequiredError, setTitleRequiredError] = useState(false);
  const [menuCategoryRequiredError, setMenuCategoryRequiredError] =
    useState(false);

  const [submit, setSubmit] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const response = await categoryService.fetchUserCategories();
      setAllCategories(response);

      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    setNewCategory({
      ...newCategory,
      menuId: filteredMenuId === 0 ? undefined : filteredMenuId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredMenuId]);

  useEffect(() => {
    (async () => {
      if (allCategories && newCategory?.menuId) {
        const currentMenuCategories = allCategories?.filter(
          (c) => c.menuId === newCategory?.menuId
        );

        setMenuCategories(currentMenuCategories);
      }
    })();
  }, [allCategories, newCategory?.menuId]);

  const createCategory = async () => {
    setTitleRequiredError(false);
    setMenuCategoryRequiredError(false);

    if (newCategory.title?.trim() === "") {
      setTitleRequiredError(true);
      return;
    }

    if ((newCategory.menuId || 0) === 0) {
      setMenuCategoryRequiredError(true);
      return;
    }

    setSubmit(true);
    await categoryService.createCategory(newCategory);
    setSubmit(false);

    const response = await categoryService.fetchUserCategories();
    setAllCategories(response);

    if (props.onSuccess) {
      notification.success({
        message: t("label.info"),
        description: t("message.categoryCreated"),
        onClick: () => {},
        top: 90,
      });

      props.onSuccess();
    }

    setNewCategory({
      title: "",
      description: "",
      orderNumber: 0,
      twoColumns: false,
      bottomNote: "",
      hideHeader: false,
      menuId: filteredMenuId === 0 ? undefined : filteredMenuId,
      hidden: false,
      orderByPrice: false,
    });
  };

  return (
    <Modal
      open={active}
      title={t("modal.title.newCategory")}
      footer={null}
      onCancel={() => {
        setActive(false);
        setNewCategory({
          title: "",
          description: "",
          orderNumber: 0,
          menuId: filteredMenuId === 0 ? undefined : filteredMenuId,
        });
      }}
    >
      <Layout>
        <Content style={{ background: "white" }}>
          <Input
            autoFocus
            placeholder={t("input.title")}
            value={newCategory.title}
            onChange={(evt) => {
              setNewCategory({ ...newCategory, title: evt.target.value });
            }}
            style={{
              marginBottom: 20,
              borderColor: titleRequiredError ? "red" : undefined,
            }}
            required
          />
          <TextArea
            placeholder={t("input.descriptionOptional")}
            autoSize={{ minRows: 3, maxRows: 6 }}
            value={newCategory.description}
            onChange={(evt) => {
              setNewCategory({ ...newCategory, description: evt.target.value });
            }}
            style={{ marginBottom: 20 }}
          />
          <Select
            showSearch
            filterOption={(input, option) => {
              if (!Array.isArray(option?.children)) {
                return (
                  (option?.children ?? "")
                    ?.toLowerCase()
                    .indexOf(input?.toLowerCase()) >= 0
                );
              } else {
                return false;
              }
            }}
            loading={loading}
            value={newCategory.menuId}
            placeholder={t("input.categoryMenu")}
            style={{
              width: "100%",
              marginBottom: 20,
              border: menuCategoryRequiredError ? "1px red solid" : undefined,
            }}
            onChange={(value: any) => {
              setNewCategory({ ...newCategory, menuId: value });
            }}
          >
            <OptGroup label={t("input.categoryMenu")}>
              {usermenus?.map((item: Menu, index: number) => {
                return (
                  <Option key={`c-c-${index}`} value={item?.id || 0}>
                    {item.description
                      ? `${item?.title} (${item?.description})`
                      : item?.title}
                  </Option>
                );
              })}
            </OptGroup>
          </Select>
          <Select
            showSearch
            filterOption={(input, option) => {
              if (!Array.isArray(option?.children)) {
                return (
                  (option?.children ?? "")
                    ?.toLowerCase()
                    .indexOf(input?.toLowerCase()) >= 0
                );
              } else {
                return false;
              }
            }}
            loading={loading}
            value={newCategory.parentId}
            placeholder={t("input.parentCategory")}
            style={{ width: "100%", marginBottom: 30 }}
            disabled={(newCategory?.menuId || 0) === 0}
            onChange={(value: any) => {
              setNewCategory({ ...newCategory, parentId: value });
            }}
          >
            <Option key={`c-c-empty`} value={0}>
              {t("label.noParentCategory")}
            </Option>
            {menuCategories?.map((item: Category, index: number) => {
              if (item.parentId === 0) {
                return (
                  <Option key={`c-c-${index}`} value={item?.id || 0}>
                    {item?.title}
                  </Option>
                );
              } else return undefined;
            })}
          </Select>
          <Space>
            <p style={{ marginBottom: 20 }}>
              <b>{t("input.orderNumber")}:</b>
            </p>
            <InputNumber
              placeholder={t("input.orderNumber")}
              value={newCategory.orderNumber}
              onChange={(value) => {
                setNewCategory({
                  ...newCategory,
                  orderNumber: value ?? 0,
                });
              }}
              style={{ marginBottom: 20 }}
            />
          </Space>
          <Space style={{ width: "100%" }}>
            <p style={{ marginBottom: 20 }}>
              <b>{t("input.orderByPrice")}:</b>
            </p>
            <Checkbox
              checked={newCategory.orderByPrice}
              onChange={(value) => {
                setNewCategory({
                  ...newCategory,
                  orderByPrice: value.target.checked,
                });
              }}
              style={{ marginBottom: 20 }}
            />
          </Space>
          <Space style={{ width: "100%" }}>
            <p style={{ marginBottom: 20 }}>
              <b>{t("input.twoColumns")}:</b>
            </p>
            <Checkbox
              checked={newCategory.twoColumns}
              onChange={(value) => {
                setNewCategory({
                  ...newCategory,
                  twoColumns: value.target.checked,
                });
              }}
              style={{ marginBottom: 20 }}
            />
          </Space>
          <Space style={{ width: "100%" }}>
            <p style={{ marginBottom: 20 }}>
              <b>{t("input.hideCategory")}:</b>
            </p>
            <Checkbox
              checked={newCategory.hidden}
              onChange={(value) => {
                setNewCategory({
                  ...newCategory,
                  hidden: value.target.checked,
                });
              }}
              style={{ marginBottom: 20 }}
            />
          </Space>
          <Space style={{ width: "100%" }}>
            <p style={{ marginBottom: 20 }}>
              <b>{t("input.hideHeader")}:</b>
            </p>
            <Checkbox
              checked={newCategory.hideHeader}
              onChange={(value) => {
                setNewCategory({
                  ...newCategory,
                  hideHeader: value.target.checked,
                });
              }}
              style={{ marginBottom: 20 }}
            />
          </Space>
          <TextArea
            placeholder={t("input.bottomNote")}
            autoSize={{ minRows: 2, maxRows: 2 }}
            value={newCategory.bottomNote}
            onChange={(evt) => {
              setNewCategory({ ...newCategory, bottomNote: evt.target.value });
            }}
            style={{ marginBottom: 20 }}
          />
        </Content>
        <Content style={{ textAlign: "right", background: "white" }}>
          <Button
            type="primary"
            style={{ width: 100 }}
            onClick={() => {
              createCategory();
            }}
            disabled={false}
          >
            {submit ? (
              <Spinner size="small" color={textColors.white} />
            ) : (
              t("button.save")
            )}
          </Button>
        </Content>
      </Layout>
    </Modal>
  );
};
