import {
  AlignRightOutlined,
  DeleteOutlined,
  EditOutlined,
  FilePdfOutlined,
  GlobalOutlined,
  QrcodeOutlined,
} from "@ant-design/icons";
import { Image, notification, Space, Table, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { menuDesignService } from "src/core/services/menuDesignService";
import { menuService } from "src/core/services/menuService";
import { getPublicMenuLink, getQrCodeLink } from "src/core/utils/menuLink";
import { MenuEditModal } from "src/modules/menuEditModal/MenuEditModal";
import { FixedType, Menu, MenuDesign } from "src/types/types";

type Props = {
  menus: Menu[];
  onEditSuccess?: () => void;
};

export const MenusTable = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [editMenu, setEditMenu] = useState<Menu>();
  const [showEdit, setShowEdit] = useState(false);
  const [menuDesigns, setMenuDesigns] = useState<MenuDesign[]>();

  const { menus } = props;

  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const response = await menuDesignService.getMenuDesigns();
      setMenuDesigns(response);

      setLoading(false);
    })();
  }, []);

  const columns = [
    {
      title: t("table.column.title"),
      key: "title",
      render: (menu: Menu) => {
        if (menu.pdfLink && menu.pdfLink !== "") {
          return (
            <Space>
              <FilePdfOutlined style={{ maxHeight: 40, fontSize: 18 }} />{" "}
              <b>{menu.title}</b>
            </Space>
          );
        }
        if (menu.headerLogo && menu.headerLogo !== "") {
          return (
            <Space>
              <Image
                src={menu.headerLogo}
                style={{ maxHeight: 40 }}
                preview={false}
              />{" "}
              <b>{menu.title}</b>
            </Space>
          );
        }
        return <b>{menu.title}</b>;
      },
      width: 150,
    },
    {
      title: t("table.column.description"),
      dataIndex: "description",
      key: "description",
      width: 200,
    },
    {
      title: t("table.column.design"),
      dataIndex: "menuDesignId",
      key: "menuDesignId",
      render: (designId: number) => {
        const label = menuDesigns?.find((design) => design.id === designId);

        return label?.title;
      },
      width: 100,
    },
    {
      title: "",
      key: "action",
      render: (item: Menu) => (
        <Space size={[8, 16]} wrap align="center">
          <EditOutlined
            title={t("table.button.edit")}
            style={{ color: "#40a9ff", cursor: "pointer" }}
            onClick={() => {
              setEditMenu(item);
              setShowEdit(true);
            }}
          />
          <Tooltip
            placement="top"
            defaultOpen={
              (item?.pdfLink || "") === "" &&
              moment().diff(moment(item.created_at), "hours") < 1
            }
            title={t("table.button.menuItems")}
          >
            <AlignRightOutlined
              title={t("table.button.menuItems")}
              style={{ color: "black", cursor: "pointer" }}
              onClick={() => {
                if (item.pdfLink && item.pdfLink !== "") {
                  notification.info({
                    message: t("label.info"),
                    description: t("message.pdfMenuCantHaveItems"),
                    onClick: () => {},
                    top: 90,
                  });
                } else {
                  history.push({
                    pathname: `/admin/menus/${item.id}/items`,
                    search: item.description
                      ? `?t=${item.title} (${item.description})`
                      : `?t=${item.title}`,
                  });
                }
              }}
            />
          </Tooltip>

          <DeleteOutlined
            title={t("table.button.delete")}
            style={{ color: "#40a9ff", cursor: "pointer" }}
            onClick={async () => {
              if (window.confirm(t("table.warning.menuDelete"))) {
                await menuService.deleteMenu(item.id || 0);

                // refrech menus
                if (props.onEditSuccess) {
                  props.onEditSuccess();
                }
              }
            }}
          />
          <a
            href={getQrCodeLink(item.identifier)}
            target="_blank"
            rel="noreferrer"
          >
            <QrcodeOutlined title={t("table.button.qrCode")} />
          </a>
          <a
            href={getPublicMenuLink(item.identifier)}
            target="_blank"
            rel="noreferrer"
          >
            <GlobalOutlined title={t("table.button.view")} />
          </a>
        </Space>
      ),
      fixed: "right" as FixedType,
      width: 100,
    },
  ];

  const onSave = async () => {
    if (props.onEditSuccess) {
      props.onEditSuccess();
    }
  };

  return (
    <React.Fragment>
      <MenuEditModal
        menuProps={editMenu}
        activeState={[showEdit, setShowEdit]}
        onSuccess={onSave}
      />
      <Table
        loading={loading}
        rowKey={({ id }) => `menu-${id}`}
        scroll={{ x: 360 }}
        columns={columns}
        dataSource={menus}
        pagination={{ hideOnSinglePage: true }}
      />
    </React.Fragment>
  );
};
