import {
  Button,
  InputNumber,
  Layout,
  Modal,
  notification,
  Radio,
  Skeleton,
  Space,
} from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { getCartId, setCartId } from "src/cartProvider";
import { Spinner } from "src/core/components/Spinner";
import { cartService } from "src/core/services/orderCartService";
import { textColors } from "src/core/style/colors";
import { MenuItemRow } from "src/pages/menuPage/components/MenuItemRow";
import { orderCartState } from "src/recoil/atoms";
import { MenuItem, OrderItem } from "src/types/types";

type Props = {
  activeState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  menuItem?: MenuItem;
  onSuccess?: () => void;
};

export const MenuItemModal = (props: Props) => {
  const { menuItem } = props;

  const [loading, setLoading] = useState(true);

  const [orderCart, setOrderCart] = useRecoilState(orderCartState);

  const [quantity, setQuantity] = useState(1);
  const [total, setTotal] = useState(menuItem?.price || 0);
  const [selectedPrice, setSelectedPrice] = useState(menuItem?.price || 0);

  const [orderItem, setOrderItem] = useState<OrderItem>({
    orderNumber: "",
    menuItemId: menuItem?.id ?? 0,
    menuItemTitle: menuItem?.title ?? "",
    quantity: 1,
    price: selectedPrice ?? 0,
    total: selectedPrice,
  });

  const [submit, setSubmit] = useState(false);

  const { t } = useTranslation();

  const {
    activeState: [active, setActive],
  } = props;

  useEffect(() => {
    (async () => {
      if (menuItem) {
        setOrderItem({
          orderNumber: "",
          menuItemId: menuItem?.id ?? 0,
          menuItemTitle: menuItem?.title ?? "",
          quantity: 1,
          price: menuItem?.price ?? 0,
          total: menuItem?.price ?? 0 * 1,
        });

        setTotal(menuItem?.price ?? 0 * 1);
        setQuantity(1);
        setSelectedPrice(menuItem?.price ?? 0);
      }

      setLoading(false);
    })();
  }, [menuItem]);

  useEffect(() => {
    (async () => {
      setTotal(quantity * (selectedPrice ?? 0));

      orderItem.price = selectedPrice;
      orderItem.quantity = quantity;
      orderItem.total = quantity * selectedPrice;
      setOrderItem(orderItem);
    })();
  }, [orderItem, quantity, selectedPrice]);

  const addToBasket = async () => {
    setSubmit(true);

    const orderCartId = orderCart?.items?.length > 0 ? getCartId() : "0";

    const addedItem = await cartService.addOrderCartItem(
      orderItem,
      orderCartId
    );

    if (orderCartId === "0") {
      setCartId(addedItem?.orderCartId ?? "0");
    }

    setOrderCart((oldOrderCart) => {
      return {
        cartId: addedItem?.orderCartId ?? "0",
        items: [...oldOrderCart.items, addedItem],
      };
    });

    notification.success({
      message: t("label.thankYou"),
      description: t("message.orderItemAdded"),
      onClick: () => {},
      top: 30,
      style: { width: "280px" },
      duration: 3,
    });

    if (props.onSuccess) {
      props.onSuccess();
    }

    setSubmit(false);
    setActive(false);
  };

  return (
    <Modal
      open={active}
      title={t("modal.title.addToBasket")}
      footer={null}
      onCancel={() => {
        setActive(false);
        setOrderItem({
          orderNumber: "",
          menuItemId: menuItem?.id,
          menuItemTitle: menuItem?.title ?? "",
          quantity: 1,
          price: selectedPrice,
          total: total,
        });
        setQuantity(1);
        setTotal(0);
      }}
    >
      <Layout>
        <Skeleton active loading={loading}>
          <Content>
            <Content style={{ background: "white" }}>
              <Content>
                <MenuItemRow
                  key={`order-i-${menuItem?.id}`}
                  index={9999}
                  item={
                    menuItem || {
                      title: "",
                      description: "",
                      orderNumber: 0,
                      menuId: 0,
                    }
                  }
                  twoColumns={false}
                />
              </Content>
              {menuItem?.secondPrice && (
                <Space>
                  <p style={{ marginBottom: 20 }}>
                    <b>{t("input.orderItemPrice")}:</b>
                  </p>
                  <Radio.Group
                    onChange={(ev) => {
                      setSelectedPrice(ev.target.value);
                    }}
                    value={selectedPrice}
                    style={{
                      marginBottom: 15,
                    }}
                  >
                    <Space direction="horizontal">
                      <Radio value={menuItem.price}>{menuItem.price}€</Radio>
                      <Radio value={menuItem?.secondPrice}>
                        {menuItem?.secondPrice}€
                      </Radio>
                    </Space>
                  </Radio.Group>
                </Space>
              )}
              <Space>
                <p style={{ marginBottom: 20 }}>
                  <b>{t("input.quantity")}:</b>
                </p>
                <InputNumber
                  placeholder={"0"}
                  value={quantity}
                  onChange={(value) => {
                    setQuantity(value ?? 0);
                    setTotal((selectedPrice || 0) * (value ?? 0));
                  }}
                  style={{
                    marginBottom: 20,
                  }}
                  min={1}
                />
              </Space>
              <Space
                style={{
                  width: "100%",
                  flexDirection: "row-reverse",
                  minWidth: 150,
                }}
              >
                <p style={{ marginBottom: 20, minWidth: 100 }}>
                  <b style={{ fontSize: "20px", marginRight: 5 }}>
                    {t("payment.total")}:
                  </b>{" "}
                  {(total ?? 0).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })}{" "}
                  €
                </p>
              </Space>
            </Content>

            <Content style={{ textAlign: "right", background: "white" }}>
              <Button
                type="primary"
                style={{ width: 100 }}
                onClick={() => {
                  addToBasket();
                }}
                disabled={(total ?? 0) === 0 || submit ? true : false}
              >
                {submit ? (
                  <Spinner size="normal" color={textColors.white} />
                ) : (
                  t("button.addToCart")
                )}
              </Button>
            </Content>
          </Content>
        </Skeleton>
      </Layout>
    </Modal>
  );
};
