import { BASE_URL, MENU_BASE_URL, ORDER_BASE_URL } from "src/constants";
import { getQueryParamValue } from "src/core/utils/utils";

export const getPublicOrderLink = (orderNumber: any): string => {
  return `${ORDER_BASE_URL}/${orderNumber}`;
};

export const getPublicMenuLink = (identifier: any): string => {
  const tableId = getQueryParamValue("t");

  const queryParams = tableId ? `?t=${tableId}` : "";

  return `${MENU_BASE_URL}/${identifier}${queryParams}`;
};

export const getQrCodeLink = (identifier: any): string => {
  return `https://api.qrserver.com/v1/create-qr-code/?size=1000x1000&data=${MENU_BASE_URL}/${identifier}`;
};

export const getUploadsRootUrl = (): string => {
  return `${BASE_URL}/uploads`;
};
