import { API_BASE_URL } from "src/constants";
import {
  deleteUnauthorized,
  getUnauthorized,
  postUnauthorized,
} from "src/core/utils/api";
import { OrderItem } from "src/types/types";

const createOrderCartService = () => {
  const addOrderCartItem = async (
    orderItem: OrderItem,
    cartId: string = "0"
  ): Promise<OrderItem> => {
    const response = await postUnauthorized<OrderItem>(
      API_BASE_URL + `/carts/${cartId}/items`,
      orderItem
    );

    return response;
  };

  const getCartItems = async (cartId: string): Promise<OrderItem[]> => {
    const response = await getUnauthorized<OrderItem[]>(
      API_BASE_URL + `/carts/${cartId}/items`
    );

    return response;
  };

  const deleteCartItem = async (
    cartId: string,
    itemId: number
  ): Promise<void> => {
    await deleteUnauthorized(
      API_BASE_URL + `/carts/${cartId}/items/${itemId}`,
      null
    );
  };

  return {
    addOrderCartItem,
    getCartItems,
    deleteCartItem,
  };
};
export const cartService = createOrderCartService();
