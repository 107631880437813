import { Button, Checkbox, Input, Layout, Modal, Select, Space } from "antd";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Spinner } from "src/core/components/Spinner";
import { menuService } from "src/core/services/menuService";
import { multiMenuService } from "src/core/services/multiMenuService";
import { textColors } from "src/core/style/colors";
import { Menu, MultiMenu, MultiMenuSection } from "src/types/types";

type Props = {
  multiMenuId: number;
  activeState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  onSuccess?: () => void;
  editMode: boolean;
  id?: number;
  title?: string;
  description?: string;
  menuIdentifier?: string;
  hidden?: boolean;
};

const { Option, OptGroup } = Select;
const { TextArea } = Input;

export const MultiMenuSectionCreateEditModal = (props: Props) => {
  const {
    multiMenuId,
    activeState: [active, setActive],
    editMode,
    id,
    title,
    description,
    menuIdentifier,
    hidden,
  } = props;

  const [currentMenuSection, setCurrentMenuSection] =
    useState<MultiMenuSection>({
      multiMenuId: props.multiMenuId,
      title: title || "",
      description: description,
      menuIdentifier: menuIdentifier,
      hidden: hidden || false,
    });

  const [loading, setLoading] = useState(true);
  const [userMenus, setUserMenus] = useState<Menu[]>([]);
  const [userMultiMenus, setUserMultiMenus] = useState<MultiMenu[]>([]);

  const [submit, setSubmit] = useState(false);
  const [titleRequiredError, setTitleRequiredError] = useState(false);
  const [menuRequiredError, setMenuRequiredError] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const response = await menuService.fetchUserMenus();
      setUserMenus(response);

      const multiResponse = await multiMenuService.fetchUserMultiMenus();
      setUserMultiMenus(multiResponse);

      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (editMode) {
      setCurrentMenuSection({
        multiMenuId: multiMenuId,
        title: title || "",
        description: description,
        menuIdentifier: menuIdentifier,
        hidden: hidden || false,
      });
    }
  }, [description, editMode, hidden, menuIdentifier, multiMenuId, title]);

  const createMenuSection = async () => {
    setTitleRequiredError(false);
    setMenuRequiredError(false);

    if (currentMenuSection.title?.trim() === "") {
      setTitleRequiredError(true);
      return;
    }

    if ((currentMenuSection.menuIdentifier || "")?.trim() === "") {
      setMenuRequiredError(true);
      return;
    }

    currentMenuSection.multiMenuId = props.multiMenuId;
    setSubmit(true);
    await multiMenuService.createMultiMenuSection(currentMenuSection);
    setSubmit(false);

    if (props.onSuccess) {
      props.onSuccess();
    }

    setCurrentMenuSection({
      title: "",
    });
  };

  const editMenuSection = async () => {
    setTitleRequiredError(false);
    setMenuRequiredError(false);

    if (currentMenuSection.title?.trim() === "") {
      setTitleRequiredError(true);
      return;
    }

    if ((currentMenuSection.menuIdentifier || "")?.trim() === "") {
      setMenuRequiredError(true);
      return;
    }

    currentMenuSection.multiMenuId = multiMenuId;
    currentMenuSection.id = id;

    setSubmit(true);
    await multiMenuService.updateMultiMenuSection(currentMenuSection);
    setSubmit(false);

    if (props.onSuccess) {
      props.onSuccess();
    }

    setCurrentMenuSection({
      title: "",
    });
  };

  return (
    <Modal
      open={active}
      title={t("modal.title.currentMenuSection")}
      footer={null}
      onCancel={() => {
        setCurrentMenuSection({
          title: "",
        });
        setActive(false);
      }}
    >
      <Layout>
        <Content style={{ background: "white" }}>
          <Input
            autoFocus
            placeholder={t("input.title")}
            value={currentMenuSection.title}
            onChange={(evt) => {
              setCurrentMenuSection({
                ...currentMenuSection,
                title: evt.target.value,
              });
            }}
            style={{
              marginBottom: 20,
              borderColor: titleRequiredError ? "red" : undefined,
            }}
            required
          />
          <TextArea
            placeholder={t("input.descriptionOptional")}
            autoSize={{ minRows: 1, maxRows: 3 }}
            value={currentMenuSection.description}
            onChange={(evt: any) => {
              setCurrentMenuSection({
                ...currentMenuSection,
                description: evt.target.value,
              });
            }}
            style={{ marginBottom: 20 }}
          />
          <Select
            loading={loading}
            value={currentMenuSection.menuIdentifier}
            placeholder={t("input.selectMenu")}
            style={{
              width: "100%",
              marginBottom: 20,
              border: menuRequiredError ? "1px red solid" : undefined,
            }}
            onChange={(value: any) => {
              setCurrentMenuSection({
                ...currentMenuSection,
                menuIdentifier: value,
              });
            }}
          >
            <OptGroup label={t("label.simpleMenus")}>
              {userMenus.map((men: Menu, index) => {
                return (
                  <Option key={`mm-s-c-${index}`} value={men.identifier || 0}>
                    {men.description
                      ? `${men.title} (${men?.description?.substring(0, 100)})`
                      : men.title}
                  </Option>
                );
              })}
            </OptGroup>
            {userMultiMenus.length > 1 && (
              <OptGroup label={t("label.multiMenus")}>
                {userMultiMenus.map((men: MultiMenu, index) => {
                  if (men.id !== props.multiMenuId) {
                    return (
                      <Option
                        key={`mmm-s-c-${index}`}
                        value={men.identifier || 0}
                      >
                        {men.description
                          ? `${men.title} (${men?.description?.substring(
                              0,
                              100
                            )})`
                          : men.title}
                      </Option>
                    );
                  } else {
                    return "";
                  }
                })}
              </OptGroup>
            )}
          </Select>
          <Space style={{ width: "100%" }}>
            <p style={{ marginBottom: 30 }}>
              <b>{t("input.hidden")}:</b>
            </p>
            <Checkbox
              checked={currentMenuSection.hidden}
              onChange={(value) => {
                setCurrentMenuSection({
                  ...currentMenuSection,
                  hidden: value.target.checked,
                });
              }}
              style={{ marginBottom: 30 }}
            />
          </Space>
        </Content>
        <Content style={{ textAlign: "right", background: "white" }}>
          <Button
            type="primary"
            style={{ width: 100 }}
            onClick={() => {
              if (editMode) {
                editMenuSection();
              } else {
                createMenuSection();
              }
            }}
            disabled={false}
          >
            {submit ? (
              <Spinner size="small" color={textColors.white} />
            ) : (
              t("button.save")
            )}
          </Button>
        </Content>
      </Layout>
    </Modal>
  );
};
