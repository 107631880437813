import { Drawer, Image } from "antd";
import { Content } from "antd/lib/layout/layout";
import React from "react";
import { MenuDesign, MenuItemsGrouped } from "src/types/types";

type Props = {
  onClose: () => void;
  showDrawer: boolean;
  menuItemsGrouped?: MenuItemsGrouped;
  menuDesign?: MenuDesign;
  headerLogo?: string;
};

export const MenuNavigationSidebar = (props: Props) => {
  const { showDrawer, onClose, menuItemsGrouped, menuDesign, headerLogo } =
    props;

  return (
    <React.Fragment>
      <Drawer
        title={
          headerLogo ? (
            <Image
              style={{
                width: "auto",
                maxHeight: 45,
                margin: "0 auto",
              }}
              src={headerLogo}
              preview={false}
              fallback={menuItemsGrouped?.title}
            />
          ) : (
            menuItemsGrouped?.title
          )
        }
        width={280}
        placement="right"
        onClose={onClose}
        open={showDrawer}
        bodyStyle={{ paddingTop: 0 }}
        headerStyle={{ paddingBottom: 0 }}
        className={
          menuDesign || false
            ? `${menuDesign.backgroundClass}-sidebar`
            : "sidebar-navigation"
        }
      >
        {menuItemsGrouped?.items?.map((group, index) => {
          if (group.hideHeader) {
            return "";
          }

          return (
            <React.Fragment key={`si-i-${index}`}>
              {group.parentCategory && (
                <Content>
                  <a
                    href={`#${group?.parentCategory?.id}-${group?.parentCategory?.title}`}
                    title={group.title}
                  >
                    <h4
                      className="sidebar-parent-category-title"
                      style={{ marginBottom: 5, marginTop: 10 }}
                    >
                      {group?.parentCategory?.title}
                    </h4>
                  </a>
                </Content>
              )}
              <Content style={{}}>
                {group.hasParent ? (
                  <a
                    href={`#${group.categoryId}-${group.title}`}
                    title={group.title}
                  >
                    <h5 style={{ marginBottom: 5, paddingLeft: 15 }}>
                      {group.title}
                    </h5>
                  </a>
                ) : (
                  <a
                    href={`#${group.categoryId}-${group.title}`}
                    title={group.title}
                  >
                    <h4 style={{ marginBottom: 10, marginTop: 10 }}>
                      {group.title}
                    </h4>
                  </a>
                )}
              </Content>
            </React.Fragment>
          );
        })}
      </Drawer>
    </React.Fragment>
  );
};
