import { Button, Layout, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { multiMenuService } from "src/core/services/multiMenuService";
import { LeftSidebar } from "src/modules/leftSidebar/LeftSidebar";
import { MultiMenuCreateModal } from "src/modules/multiMenuCreateModal/MultiMenuCreateModal";
import { MultiMenusTable } from "src/modules/multiMenusTable/MultiMenusTable";
import { ContentPageHeader } from "src/modules/pageHeader/PageHeader";
import { MultiMenu } from "src/types/types";

const { Content } = Layout;

export const UserMultiMenusPage = () => {
  const [loading, setLoading] = useState(true);
  const [createMenu, setCreateMenu] = useState(false);
  const [userMenus, setUserMenus] = useState<MultiMenu[]>([]);

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const response = await multiMenuService.fetchUserMultiMenus();
      setUserMenus(response);

      setLoading(false);
    })();
  }, []);

  var buttons = [];

  buttons.push(
    <Button
      onClick={() => {
        setCreateMenu(true);
      }}
      key="add-button"
      type="primary"
    >
      {t("button.createMenu")}
    </Button>
  );

  const onMenuCreated = async () => {
    setCreateMenu(false);
    setLoading(true);

    const response = await multiMenuService.fetchUserMultiMenus();
    setUserMenus(response);

    setLoading(false);
  };

  const onEditSuccess = async () => {
    setLoading(true);
    const response = await multiMenuService.fetchUserMultiMenus();
    setUserMenus(response);

    setLoading(false);
  };

  return (
    <Layout>
      <LeftSidebar page="multi-menus" />
      <MultiMenuCreateModal
        key="multi-menu-create-modal"
        activeState={[createMenu, setCreateMenu]}
        onSuccess={onMenuCreated}
      />
      <Content style={{ textAlign: "center", padding: "5px" }}>
        <Layout>
          <ContentPageHeader
            pageTitle={t("title.multiMenus")}
            buttons={buttons}
          />
          <Skeleton active loading={loading}>
            <Content style={{ padding: 25 }}>
              <MultiMenusTable
                menus={userMenus}
                onEditSuccess={onEditSuccess}
              />
            </Content>
          </Skeleton>
        </Layout>
      </Content>
    </Layout>
  );
};
