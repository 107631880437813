import {
  CloseOutlined,
  FilePdfOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Image,
  Input,
  Layout,
  Modal,
  notification,
  Radio,
  Select,
  Space,
  Upload,
} from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { Content } from "antd/lib/layout/layout";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getAccessToken } from "src/authProvider";
import { API_BASE_URL } from "src/constants";
import { Spinner } from "src/core/components/Spinner";
import { menuDesignService } from "src/core/services/menuDesignService";
import { menuService } from "src/core/services/menuService";
import { textColors } from "src/core/style/colors";
import { MenuType } from "src/enums";
import { Menu, MenuDesign } from "src/types/types";

type Props = {
  activeState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
  onSuccess?: (menuId: number) => void;
};

const { Option } = Select;
const { TextArea } = Input;

export const MenuCreateModal = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [menuDesigns, setMenuDesigns] = useState<MenuDesign[]>();
  const [newMenu, setNewMenu] = useState<Menu>({
    description: "",
    title: "",
    menuDesignId: 0,
  });
  const [titleRequiredError, setTitleRequiredError] = useState(false);
  const [pdfLinkRequiredError, setPdfLinkRequiredError] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [currentMenuType, setCurrentMenuType] = useState(MenuType.SimpleMenu);

  const { t } = useTranslation();

  const {
    activeState: [active, setActive],
  } = props;

  const uploadProps = {
    name: "file",
    action: `${API_BASE_URL}/files/images/upload`,
    headers: {
      authorization: `Bearer ${getAccessToken()}`,
    },
    onChange(info: any) {
      if (info.file.status !== "uploading") {
        //console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        console.log(info.file);
        setNewMenu({
          ...newMenu,
          headerLogo: `${info.file.response.url}`,
        });
      } else if (info.file.status === "error") {
        // handle error
      }
    },
  };

  const pdfUploadProps = {
    name: "file",
    action: `${API_BASE_URL}/files/pdfs/upload`,
    headers: {
      authorization: `Bearer ${getAccessToken()}`,
    },
    onChange(info: any) {
      if (info.file.status !== "uploading") {
        //console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        console.log(info.file);
        setNewMenu({
          ...newMenu,
          pdfLink: `${info.file.response.url}`,
        });
      } else if (info.file.status === "error") {
        // handle error
      }
    },
  };

  useEffect(() => {
    (async () => {
      const response = await menuDesignService.getMenuDesigns();
      setMenuDesigns(response);

      setLoading(false);
    })();
  }, []);

  const createMenu = async () => {
    setTitleRequiredError(false);
    setPdfLinkRequiredError(false);

    if ((newMenu.title || "").trim() === "") {
      setTitleRequiredError(true);
      return;
    }

    if (
      currentMenuType === MenuType.PdfMenu &&
      (newMenu.pdfLink || "").trim() === ""
    ) {
      setPdfLinkRequiredError(true);
      return;
    }

    setSubmit(true);

    if (currentMenuType === MenuType.SimpleMenu) {
      newMenu.pdfLink = "";
    }

    const result = await menuService.createMenu(newMenu);

    setSubmit(false);

    if (props.onSuccess) {
      notification.success({
        message: t("label.info"),
        description:
          newMenu.pdfLink && newMenu.pdfLink !== ""
            ? t("message.pdfMenuCreated")
            : t("message.menuCreated"),
        onClick: () => {},
        top: 90,
        duration: 5,
      });

      let resultMenuId =
        newMenu.pdfLink && newMenu.pdfLink !== "" ? 0 : result?.id ?? 0;

      props.onSuccess(resultMenuId);
    }

    setNewMenu({
      description: "",
      title: "",
      menuDesignId: 0,
    });

    setCurrentMenuType(MenuType.SimpleMenu);
  };

  return (
    <Modal
      open={active}
      title={t("modal.title.newMenu")}
      footer={null}
      onCancel={() => {
        setActive(false);
        setNewMenu({
          description: "",
          title: "",
          menuDesignId: 0,
        });
        setCurrentMenuType(MenuType.SimpleMenu);
      }}
    >
      <Layout>
        <Content style={{ background: "white" }}>
          <Input
            autoFocus
            placeholder={t("input.title")}
            value={newMenu.title}
            onChange={(evt) => {
              setNewMenu({ ...newMenu, title: evt.target.value });
            }}
            style={{
              marginBottom: 20,
              borderColor: titleRequiredError ? "red" : undefined,
            }}
            required
          />
          <TextArea
            placeholder={t("input.descriptionOptional")}
            autoSize={{ minRows: 3, maxRows: 6 }}
            value={newMenu.description}
            onChange={(evt) => {
              setNewMenu({ ...newMenu, description: evt.target.value });
            }}
            style={{ marginBottom: 20 }}
          />
          <Radio.Group
            style={{ marginBottom: 20 }}
            value={currentMenuType}
            onChange={(e) => setCurrentMenuType(e.target.value)}
          >
            <Radio value={MenuType.SimpleMenu}>{t("label.simpleMenu")}</Radio>
            <Radio value={MenuType.PdfMenu}>{t("label.pdfMenu")}</Radio>
          </Radio.Group>
          {currentMenuType === MenuType.PdfMenu && (
            <Space style={{ marginBottom: 15, width: "100%" }}>
              <p style={{ marginBottom: 5 }}>
                <b>{t("input.pdfFile")}:</b>
              </p>
              {newMenu.pdfLink && newMenu.pdfLink !== "" ? (
                <Space>
                  <a href={newMenu.pdfLink} target="_blank" rel="noreferrer">
                    <FilePdfOutlined style={{ maxHeight: 40, fontSize: 18 }} />
                  </a>
                  <CloseOutlined
                    onClick={() => {
                      setNewMenu({ ...newMenu, pdfLink: "" });
                    }}
                  />
                </Space>
              ) : (
                <Upload
                  {...pdfUploadProps}
                  accept={".pdf"}
                  maxCount={1}
                  style={{
                    marginBottom: 20,
                  }}
                >
                  <Button
                    icon={<UploadOutlined />}
                    style={{
                      borderColor: pdfLinkRequiredError ? "red" : undefined,
                    }}
                  >
                    {t("input.file")}
                  </Button>
                </Upload>
              )}
              <Input
                type={"hidden"}
                placeholder={t("input.pdfLink")}
                value={newMenu.pdfLink}
                onChange={(evt) => {
                  setNewMenu({ ...newMenu, pdfLink: evt.target.value });
                }}
                style={{ marginBottom: 20 }}
              />
            </Space>
          )}
          {currentMenuType === MenuType.SimpleMenu && (
            <Select
              loading={loading}
              value={newMenu.menuDesignId}
              placeholder={t("input.selectStyle")}
              style={{ width: "100%", marginBottom: 30 }}
              onChange={(value: any) => {
                setNewMenu({ ...newMenu, menuDesignId: value });
              }}
            >
              {menuDesigns?.map((item: MenuDesign, index: number) => {
                return (
                  <Option key={`m-c-${index}`} value={item?.id || 0}>
                    {item?.title}
                  </Option>
                );
              })}
            </Select>
          )}
          {currentMenuType === MenuType.SimpleMenu && (
            <Space style={{ marginBottom: 15 }}>
              <p style={{ marginBottom: 5 }}>
                <b>{t("input.menuLogo")}:</b>
              </p>
              {newMenu.headerLogo && newMenu.headerLogo !== "" ? (
                <Space>
                  <Image
                    src={newMenu.headerLogo}
                    style={{ maxHeight: 80 }}
                    preview={false}
                  />
                  <CloseOutlined
                    onClick={() => {
                      setNewMenu({ ...newMenu, headerLogo: "" });
                    }}
                  />
                </Space>
              ) : (
                <Upload
                  {...uploadProps}
                  accept={".png, .jpg, .jpeg"}
                  maxCount={1}
                  style={{ marginBottom: 20 }}
                >
                  <Button icon={<UploadOutlined />}>{t("input.file")}</Button>
                </Upload>
              )}
              <Input
                type={"hidden"}
                placeholder={t("input.menuLogo")}
                value={newMenu.headerLogo}
                onChange={(evt) => {
                  setNewMenu({ ...newMenu, headerLogo: evt.target.value });
                }}
                style={{ marginBottom: 20 }}
                required
              />
            </Space>
          )}
          {currentMenuType === MenuType.SimpleMenu && (
            <Space style={{ width: "100%" }}>
              <p style={{ marginBottom: 20 }}>
                <b>{t("input.showSidebar")}:</b>
              </p>
              <Checkbox
                checked={newMenu.showSidebar}
                onChange={(value) => {
                  setNewMenu({
                    ...newMenu,
                    showSidebar: value.target.checked,
                  });
                }}
                style={{ marginBottom: 20 }}
              />
            </Space>
          )}
          {currentMenuType === MenuType.SimpleMenu && (
            <Space style={{ width: "100%" }}>
              <p style={{ marginBottom: 30 }}>
                <b>{t("input.ordersEnabled")}:</b>
              </p>
              <Checkbox
                checked={newMenu.ordersEnabled}
                onChange={(value) => {
                  setNewMenu({
                    ...newMenu,
                    ordersEnabled: value.target.checked,
                  });
                }}
                style={{ marginBottom: 30 }}
              />
            </Space>
          )}
        </Content>
        <Content style={{ textAlign: "right", background: "white" }}>
          <Button
            type="primary"
            style={{ width: 100 }}
            onClick={() => {
              createMenu();
            }}
            disabled={false}
          >
            {submit ? (
              <Spinner size="small" color={textColors.white} />
            ) : (
              t("button.save")
            )}
          </Button>
        </Content>
      </Layout>
    </Modal>
  );
};
