import {
  AlignLeftOutlined,
  CheckSquareOutlined,
  FormatPainterOutlined,
  LineChartOutlined,
  QrcodeOutlined,
  QuestionCircleOutlined,
  ReadOutlined,
  StarOutlined,
  UnorderedListOutlined,
  UserOutlined,
} from "@ant-design/icons";
import styled from "@emotion/styled";
import { Layout, Menu, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useViewPortQuery } from "src/core/hooks/useViewPortQuery";

type pageName =
  | "admin"
  | "menus"
  | "multi-menus"
  | "categories"
  | "help"
  | "orders"
  | "menu-designs";

type Props = {
  page?: pageName;
};

const { Sider } = Layout;

const Title = styled.span`
  font-size: 27px;
  line-height: 35px;
  font-family: "Segoe UI Light";
  cursor: pointer;
`;

const BrandStack = styled("div")`
  cursor: pointer;
  width: 100%;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
`;

export const LeftSidebar = (props: Props) => {
  const history = useHistory();
  const isMobile = useViewPortQuery() === "mobile";

  const [isCollapsed, setIsCollapsed] = useState(isMobile);

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {})();
  }, []);

  return (
    <Sider
      collapsible
      defaultCollapsed={isMobile}
      onCollapse={(collapsed: boolean) => {
        setIsCollapsed(collapsed);
      }}
      style={{
        height: "100vh",
        left: 0,
      }}
    >
      <BrandStack>
        <Title
          onClick={() => {
            history.push("/admin");
          }}
          style={{ color: "white" }}
        >
          {isMobile || isCollapsed ? "ML" : "MenuLink"}
        </Title>
      </BrandStack>
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={["1"]}
        selectedKeys={[props.page ?? ""]}
      >
        <Menu.Item
          key="admin"
          icon={<UserOutlined />}
          onClick={() => history.push("/admin")}
        >
          <Tooltip placement="right" title={t("sidebar.home")}>
            {t("sidebar.home")}
          </Tooltip>
        </Menu.Item>
        <Menu.Item
          key="menus"
          icon={<AlignLeftOutlined />}
          onClick={() => history.push("/admin/menus")}
        >
          <Tooltip placement="right" title={t("sidebar.userMenus")}>
            {t("sidebar.userMenus")}
          </Tooltip>
        </Menu.Item>
        <Menu.Item
          key="categories"
          icon={<UnorderedListOutlined />}
          onClick={() => history.push("/admin/categories")}
        >
          <Tooltip placement="right" title={t("sidebar.categories")}>
            {t("sidebar.categories")}
          </Tooltip>
        </Menu.Item>
        <Menu.Item
          key="multi-menus"
          icon={<ReadOutlined />}
          onClick={() => history.push("/admin/multi-menus")}
        >
          <Tooltip placement="right" title={t("sidebar.multiMenus")}>
            {t("sidebar.multiMenus")}
          </Tooltip>
        </Menu.Item>
        <Menu.Item
          key="menu-designs"
          icon={<FormatPainterOutlined />}
          onClick={() => history.push("/admin/menu-designs")}
        >
          <Tooltip placement="right" title={t("sidebar.designs")}>
            {t("sidebar.designs")}
          </Tooltip>
        </Menu.Item>
        <Menu.Item
          key="orders"
          icon={<CheckSquareOutlined />}
          onClick={() => history.push("/admin/orders")}
        >
          <Tooltip placement="right" title={t("sidebar.orders")}>
            {t("sidebar.orders")}
          </Tooltip>
        </Menu.Item>
        <Menu.Item disabled key="qr-generator" icon={<QrcodeOutlined />}>
          <Tooltip placement="right" title={t("sidebar.soon")}>
            {t("sidebar.qrGenerator")}
          </Tooltip>
        </Menu.Item>
        <Menu.Item disabled key="reviews" icon={<StarOutlined />}>
          <Tooltip placement="right" title={t("sidebar.disabledMessage")}>
            {t("sidebar.reviews")}
          </Tooltip>
        </Menu.Item>
        <Menu.Item disabled key="statistics" icon={<LineChartOutlined />}>
          <Tooltip placement="right" title={t("sidebar.disabledMessage")}>
            {t("sidebar.statistics")}
          </Tooltip>
        </Menu.Item>
        <Menu.Item
          key="help"
          icon={<QuestionCircleOutlined />}
          onClick={() => {
            history.push("/admin/help");
          }}
        >
          <Tooltip placement="right" title={t("sidebar.help")}>
            {t("sidebar.help")}
          </Tooltip>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};
