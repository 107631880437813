import { Space } from "antd";
import { Menu } from "src/types/types";

type Props = {
  menu?: Menu;
  tripAdvisor?: string;
  facebook?: string;
  instagram?: string;
  google?: string;
  size: number;
};

export const SocialIcons = (props: Props) => {
  const { menu, tripAdvisor, facebook, size, instagram, google } = props;

  return (
    <div
      style={{
        float: "right",
        position: "fixed",
        //bottom: "15%",
        top: "10%",
        width: size + 4,
        cursor: "pointer",
        overflow: "hidden",
        right: "5px",
        textAlign: "right",
        zIndex: 99999,
      }}
      title={menu?.title}
    >
      {tripAdvisor && (
        <Space style={{ marginBottom: "6px" }}>
          <a
            href={tripAdvisor}
            target={"_blank"}
            rel="noreferrer"
            style={{ marginBottom: "4px" }}
          >
            <img
              src="https://menulink.lt/images/icons/tripadvisor3.png"
              alt="Trip Advisor"
              width={size}
              height={size}
            />
          </a>
        </Space>
      )}
      {facebook && (
        <Space style={{ marginBottom: "6px" }}>
          <a
            href={facebook}
            target={"_blank"}
            rel="noreferrer"
            style={{ marginBottom: "4px" }}
          >
            <img
              src="https://menulink.lt/images/icons/facebook.png"
              alt="Facebook"
              width={size}
              height={size}
            />
          </a>
        </Space>
      )}
      {instagram && (
        <Space style={{ marginBottom: "6px" }}>
          <a
            href={instagram}
            target={"_blank"}
            rel="noreferrer"
            style={{ marginBottom: "4px" }}
          >
            <img
              src="https://menulink.lt/images/icons/instagram.png"
              alt="Facebook"
              width={size}
              height={size}
            />
          </a>
        </Space>
      )}
      {google && (
        <Space>
          <a
            href={google}
            target={"_blank"}
            rel="noreferrer"
            style={{ marginBottom: "4px" }}
          >
            <img
              src="https://menulink.lt/images/icons/google.png"
              alt="Facebook"
              width={size}
              height={size}
            />
          </a>
        </Space>
      )}
    </div>
  );
};
