import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";
import { messagingColors } from "src/core/style/colors";

type Size = "small" | "normal" | "large" | "larger";

type SpinnerProps = {
  size?: Size;
  delay?: number;
  color?: string;
};

const getFontSize = (size?: Size) => {
  switch (size) {
    case "larger":
      return 48;
    case "large":
      return 36;
    case "normal":
      return 24;
    case "small":
      return 16;
    default:
      return 24;
  }
};

export const Spinner = (props: SpinnerProps) => {
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: getFontSize(props.size),
        color: props.color ?? messagingColors.ctaBlue,
      }}
      spin
    />
  );
  return <Spin delay={props.delay} indicator={antIcon} />;
};
