import { API_BASE_URL } from "src/constants";
import { get, post } from "src/core/utils/api";
import { SignInResponse } from "src/types/types";

const createAuthService = () => {
  const signIn = async (
    username: string,
    password: string
  ): Promise<SignInResponse> => {
    const response = await post<SignInResponse>(API_BASE_URL + `/login`, {
      email: username,
      password: password,
    });

    if (response.error) {
      response.success = false;
    } else {
      response.success = true;
    }

    return response;
  };

  const signUp = async (
    name: string,
    username: string,
    password: string
  ): Promise<SignInResponse> => {
    const response = await post<SignInResponse>(API_BASE_URL + `/register`, {
      name: name,
      email: username,
      password: password,
    });

    if (response.error) {
      response.success = false;
    } else {
      response.success = true;
    }

    return response;
  };

  const checkAuth = async () => {
    await get(API_BASE_URL + `/auth`);
  };

  return {
    signIn,
    signUp,
    checkAuth,
  };
};

export const authService = createAuthService();
