import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Space, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { menuService } from "src/core/services/menuService";
import { MenuItemEditModal } from "src/modules/menuItemEditModal/MenuItemEditModal";
import { Category, FixedType, MenuItem } from "src/types/types";

type Props = {
  menuItems: MenuItem[];
  userCategories: Category[];
  onEditSuccess?: () => void;
  onPaginationChange: (size: number) => void;
  onPageChange: (page: number) => void;
  pageSize: number;
  currentPage: number;
};

export const MenusItemsTable = (props: Props) => {
  const {
    menuItems,
    userCategories,
    onPaginationChange,
    pageSize,
    onPageChange,
    currentPage,
  } = props;

  const [loading, setLoading] = useState(true);
  const [editMenuItem, setEditMenuItem] = useState<MenuItem>();
  const [showEdit, setShowEdit] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      setLoading(false);
    })();
  }, []);

  const columns = [
    {
      title: t("table.column.title"),
      key: "title",
      render: (menuItem: MenuItem) =>
        menuItem.hidden ? (
          <b style={{ color: "gray", textDecoration: "line-through" }}>
            {menuItem.title}
          </b>
        ) : (
          <b>{menuItem.title}</b>
        ),
      width: 150,
    },
    {
      title: t("table.column.description"),
      dataIndex: "description",
      key: "description",
      width: 150,
    },
    {
      title: t("table.column.category"),
      dataIndex: "categoryId",
      key: "categoryId",
      width: 150,
      render: (catId: number) => {
        const currentCategory = userCategories.find((cat) => cat.id === catId);

        let label = currentCategory?.title;

        if ((currentCategory?.parentId || 0) > 0) {
          const parent = userCategories.find(
            (cat) => cat.id === currentCategory?.parentId
          );

          if (parent) {
            label = `${currentCategory?.title} (${parent?.title})`;
          }
        }

        return (
          <span
            style={{
              textDecoration: currentCategory?.hidden ? "line-through" : "none",
            }}
          >
            {label}
          </span>
        );
      },
    },
    {
      title: t("table.column.orderNumber"),
      dataIndex: "orderNumber",
      key: "orderNumber",
      width: 70,
    },
    {
      title: t("table.column.price"),
      key: "price",
      width: 80,
      render: (item: MenuItem) => {
        const result = `${item.price} ${
          item.secondPrice ? " / " + item.secondPrice : ""
        }`;

        return result;
      },
    },
    {
      title: "",
      key: "action",
      render: (item: MenuItem) => (
        <Space size="middle" align="center" wrap>
          <EditOutlined
            title={t("table.button.editMenuItem")}
            style={{ color: "#40a9ff", cursor: "pointer" }}
            onClick={() => {
              setEditMenuItem(item);
              setShowEdit(true);
            }}
          />
          <DeleteOutlined
            title={t("table.button.delete")}
            style={{ color: "#40a9ff", cursor: "pointer" }}
            onClick={async () => {
              if (window.confirm(t("table.warning.menuItemDelete"))) {
                await menuService.deleteMenuItem(item);

                // refresh items
                if (props.onEditSuccess) {
                  props.onEditSuccess();
                }
              }
            }}
          />
        </Space>
      ),
      fixed: "right" as FixedType,
      width: 80,
    },
  ];

  const onSave = async () => {
    if (props.onEditSuccess) {
      props.onEditSuccess();
    }
  };

  return (
    <React.Fragment>
      <MenuItemEditModal
        menuItemProps={editMenuItem}
        activeState={[showEdit, setShowEdit]}
        onSuccess={onSave}
      />
      <Table
        key={"menu-items-table"}
        rowKey={({ id }) => `men-item-${id}`}
        scroll={{ x: 340 }}
        loading={loading}
        columns={columns}
        dataSource={menuItems}
        pagination={{
          hideOnSinglePage: true,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          current: currentPage,
        }}
        onChange={(pagination) => {
          if (pagination.pageSize) {
            onPaginationChange(pagination.pageSize);
          }
          if (pagination.current) {
            onPageChange(pagination.current);
          }
        }}
      />
    </React.Fragment>
  );
};
