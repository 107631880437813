import { Button, Layout, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { menuService } from "src/core/services/menuService";
import { LeftSidebar } from "src/modules/leftSidebar/LeftSidebar";
import { MenuCreateModal } from "src/modules/menuCreateModal/MenuCreateModal";
import { MenusTable } from "src/modules/menusTable/MenusTable";
import { ContentPageHeader } from "src/modules/pageHeader/PageHeader";
import { Menu } from "src/types/types";

const { Content } = Layout;

export const UserMenusPage = () => {
  const [loading, setLoading] = useState(true);
  const [createMenu, setCreateMenu] = useState(false);
  const [userMenus, setUserMenus] = useState<Menu[]>([]);

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const response = await menuService.fetchUserMenus();
      setUserMenus(response);

      setLoading(false);
    })();
  }, []);

  var buttons = [];

  buttons.push(
    <Button
      onClick={() => {
        setCreateMenu(true);
      }}
      key="add-button"
      type="primary"
    >
      {t("button.createMenu")}
    </Button>
  );

  const onMenuCreated = async (menuId: number) => {
    setCreateMenu(false);
    setLoading(true);

    const response = await menuService.fetchUserMenus();
    setUserMenus(response);

    setLoading(false);

    if (menuId > 0) {
      history.push(`/admin/categories?menuId=${menuId}`);
    }
  };

  const onEditSuccess = async () => {
    setLoading(true);
    const response = await menuService.fetchUserMenus();
    setUserMenus(response);

    setLoading(false);
  };

  return (
    <Layout>
      <LeftSidebar page="menus" />
      <MenuCreateModal
        key="menu-create-modal"
        activeState={[createMenu, setCreateMenu]}
        onSuccess={onMenuCreated}
      />
      <Content style={{ textAlign: "center", padding: "5px" }}>
        <Layout>
          <ContentPageHeader pageTitle={t("title.menus")} buttons={buttons} />
          <Skeleton active loading={loading}>
            <Content style={{ padding: 25 }}>
              {userMenus && userMenus.length === 0 && (
                <Content style={{ textAlign: "left", marginBottom: "25px" }}>
                  {t("message.noMenus")}
                </Content>
              )}
              <MenusTable menus={userMenus} onEditSuccess={onEditSuccess} />
            </Content>
          </Skeleton>
        </Layout>
      </Content>
    </Layout>
  );
};
