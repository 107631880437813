import { GlobalOutlined, ZoomInOutlined } from "@ant-design/icons";
import { Alert, Space, Table } from "antd";
import Link from "antd/lib/typography/Link";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useViewPortQuery } from "src/core/hooks/useViewPortQuery";
import { getPublicOrderLink } from "src/core/utils/menuLink";
import { OrderItemsModal } from "src/modules/orderItemsModal/OrderItemsModal";
import { FixedType, Order } from "src/types/types";

type Props = {
  orders: Order[];
  onEditSuccess?: () => void;
};

export const OrdersTable = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [selectedOrder, setSelectedOrder] = useState<Order>({
    orderNumber: "",
    status: "",
    total: 0,
  });
  const [showOrderItems, setShowOrderItems] = useState(false);

  const { orders } = props;

  const history = useHistory();
  const { t } = useTranslation();
  const isMobile = useViewPortQuery() === "mobile";

  useEffect(() => {
    (async () => {
      setLoading(false);
    })();
  }, []);

  const columns = [
    {
      title: t("table.column.userOrderNumber"),
      key: "title",
      render: (order: Order) => {
        return (
          <Link
            onClick={() => {
              setSelectedOrder(order);
              setShowOrderItems(true);
            }}
          >
            <b>{order.orderNumber}</b>
          </Link>
        );
      },
      width: isMobile ? 80 : 100,
    },
    {
      title: t("table.column.total"),
      key: "total",
      width: 80,
      render: (order: Order) => {
        return `${order.total}€`;
      },
      style: { textAlign: "right" },
    },
    {
      title: t("table.column.tableId"),
      key: "tableId",
      width: 80,
      render: (order: Order) => {
        return `${order.tableId ?? ""}`;
      },
    },
    {
      title: t("table.column.orderDate"),
      key: "orderDate",
      width: 100,
      render: (order: Order) => {
        return `${order.created_at}`;
      },
    },
    {
      title: t("table.column.paymentDate"),
      key: "orderDate",
      width: 100,
      render: (order: Order) => {
        return `${order.paymentDateTime ?? "N/A"}`;
      },
    },
    {
      title: t("table.column.status"),
      key: "status",
      width: 120,
      render: (order: Order) => {
        return (
          <Alert
            message={t(`orderStatus.${order?.status}`)}
            type={
              order?.status === "started"
                ? "warning"
                : order?.status === "accepted" ||
                  order?.status === "paidAndAccepted"
                ? "info"
                : order?.status === "completed"
                ? "success"
                : order?.status === "paid"
                ? "warning"
                : "error"
            }
            showIcon={!isMobile}
          />
        );
      },
    },
    {
      title: "",
      key: "action",
      render: (item: Order) => (
        <Space size={[8, 16]} wrap align="center">
          <ZoomInOutlined
            title={t("table.button.orderItems")}
            style={{ color: "#40a9ff", cursor: "pointer" }}
            onClick={() => {
              setSelectedOrder(item);
              setShowOrderItems(true);
            }}
          />
          <a
            href={getPublicOrderLink(item.orderNumber)}
            target="_blank"
            rel="noreferrer"
          >
            <GlobalOutlined title={t("table.button.view")} />
          </a>
        </Space>
      ),
      fixed: "right" as FixedType,
      width: 50,
    },
  ];

  const onSave = async () => {
    if (props.onEditSuccess) {
      props.onEditSuccess();
    }
  };

  return (
    <React.Fragment>
      <OrderItemsModal
        activeState={[showOrderItems, setShowOrderItems]}
        order={selectedOrder}
        onSaveSuccess={onSave}
      />
      <Table
        loading={loading}
        rowKey={({ id }) => `order-${id}`}
        scroll={{ x: 360 }}
        columns={columns}
        dataSource={orders}
        pagination={{ hideOnSinglePage: true }}
      />
    </React.Fragment>
  );
};
