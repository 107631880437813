import { getClientStorage } from "src/core/utils/clientStorage";

const { getData, setData } = getClientStorage("session", "user.token", {
  token: "",
});

const checkIfUserIsAuthorized = async () => {
  try {
    let result = { authorized: false };
    //create an axios instance that can handle error codes.
    const token = await getAccessToken();

    if (token) {
      /*const axInstance = axios.create({
        baseURL: API_BASE_URL,
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        validateStatus: function (status) {
          return status >= 200 && status <= 404;
        },
      });

      result = await axInstance.get("/v1/auth").then((response) => {
        const notAllowed = response.status === 403; // forbidden
        const authorized = response.status === 200; // forbidden
        return {
          notAllowed: notAllowed,
          authorized: authorized,
          error: response.data.error,
        };
      });*/
    }

    return result;
  } catch (e) {
    console.log(e);
  }
};

const getAccessToken = (): string => {
  try {
    const storedToken = getData();
    return storedToken.token;
  } catch (err) {
    console.log(err);
    return "";
  }
};

const setAccessToken = async (tokenValue: string) => {
  try {
    setData({ token: tokenValue });
    return;
  } catch (err) {
    console.log(err);
    return err;
  }
};

const signOut = async () => {
  try {
    setData({ token: "" });

    let hostFound = false;

    if (window.location.host.includes("menulink.lt")) {
      window.location.replace("https://menulink.lt");
      hostFound = true;
    }

    if (window.location.host.includes("menulink.eu")) {
      window.location.replace("https://menulink.eu");
      hostFound = true;
    }

    if (window.location.host.includes("localhost")) {
      window.location.replace("http://localhost:3000");
      hostFound = true;
    }

    if (!hostFound) {
      window.location.replace("https://menulink.lt");
    }

    return;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export { getAccessToken, setAccessToken, checkIfUserIsAuthorized, signOut };
