import { getClientStorage } from "src/core/utils/clientStorage";

const { getData, setData } = getClientStorage("session", "orderCart.id", {
  token: "",
});
const getCartId = (): string => {
  try {
    const storedToken = getData();
    return storedToken.token;
  } catch (err) {
    console.log(err);
    return "";
  }
};

const setCartId = async (tokenValue: string) => {
  try {
    setData({ token: tokenValue });
    return;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export { getCartId, setCartId };
