export const getClientStorage = <T extends object>(
  kind: "local" | "session",
  key: string,
  defaultData: T
) => {
  const storageKey = `menulink.${key}`;

  const storage: Storage = kind === "local" ? localStorage : sessionStorage;

  const getData = (): T => {
    const item = storage.getItem(storageKey);

    if (!item) {
      return defaultData;
    }

    try {
      return JSON.parse(item);
    } catch (error) {
      console.error(error);
      return defaultData;
    }
  };

  const setData = (data: T) => {
    try {
      storage.setItem(storageKey, JSON.stringify(data));
    } catch (error) {
      console.log(error);
    }
  };

  return { getData, setData };
};
