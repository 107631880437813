import { Button, Layout } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { PageFooter } from "src/modules/pageFooter/PageFooter";

const { Header, Content } = Layout;

export const ForgetPasswordPage = () => {
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {})();
  }, []);

  return (
    <Layout>
      <Header
        style={{
          background: "white",
          height: 80,
          textAlign: "center",
          lineHeight: "20px",
          padding: "25px",
        }}
      >
        <h1>{t("home.header")}</h1>
      </Header>
      <Content
        style={{
          textAlign: "center",
          padding: "50px",
          maxWidth: "600px",
          margin: "0 auto",
        }}
      >
        <p>{t("forgotPassword.info")}</p>
        <Button
          type="default"
          style={{ width: "100px", marginTop: 30 }}
          onClick={() => {
            history.push("/signin");
          }}
        >
          {t("button.back")}
        </Button>
      </Content>
      <PageFooter />
    </Layout>
  );
};
