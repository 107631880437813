import { Button, Form, Input, Layout, Space } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { setAccessToken } from "src/authProvider";
import { Spinner } from "src/core/components/Spinner";
import { authService } from "src/core/services/authService";
import { textColors } from "src/core/style/colors";
import { PageFooter } from "src/modules/pageFooter/PageFooter";

const { Header, Content } = Layout;

export const RegistrationPage = () => {
  const [loading, setLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [registrationError, setRegistrationError] = useState(false);

  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      setLoading(false);
    })();
  }, []);

  const onFinish = async (values: any) => {
    const response = await authService.signUp(
      values["name"],
      values["username"],
      values["password"]
    );

    if (!response.success) {
      setRegistrationError(true);
      setSubmitted(false);
    } else {
      setAccessToken(response.token);
      history.push("/admin?firstTime=1");
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    setSubmitted(false);
    console.log("Failed:", errorInfo);
  };

  return (
    <Layout>
      <Header
        style={{
          background: "white",
          height: 80,
          textAlign: "center",
          lineHeight: "20px",
          padding: "25px",
        }}
      >
        <h1>{t("home.header")}</h1>
      </Header>
      <Content
        style={{
          textAlign: "center",
          padding: "50px",
          maxWidth: "600px",
          margin: "0 auto",
        }}
      >
        <Form
          name="basic"
          labelCol={{ span: 10 }}
          wrapperCol={{ span: 16 }}
          initialValues={{}}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          onSubmitCapture={() => {
            setSubmitted(true);
          }}
        >
          <Form.Item
            label={t("input.nameSurname")}
            name="name"
            rules={[{ required: true, message: t("warning.fieldRequired") }]}
          >
            <Input
              onChange={() => {
                setRegistrationError(false);
              }}
            />
          </Form.Item>
          <Form.Item
            label={t("input.email")}
            name="username"
            rules={[{ required: true, message: t("warning.fieldRequired") }]}
          >
            <Input
              type="email"
              onChange={() => {
                setRegistrationError(false);
              }}
            />
          </Form.Item>

          <Form.Item
            label={t("input.password")}
            name="password"
            rules={[{ required: true, message: t("warning.fieldRequired") }]}
          >
            <Input.Password
              onChange={() => {
                setRegistrationError(false);
              }}
            />
          </Form.Item>

          {registrationError && (
            <div style={{ paddingBottom: "10px", color: "red" }}>
              {t("error.unexpected")}
            </div>
          )}

          <Form.Item
            wrapperCol={{ offset: 0, span: 16 }}
            style={{ flexDirection: "row-reverse" }}
          >
            <Space>
              <Button
                type="default"
                style={{ width: "100px", marginRight: 10 }}
                onClick={() => {
                  history.push("/signin");
                }}
              >
                {t("button.back")}
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: "100px" }}
                onClick={() => {
                  setRegistrationError(false);
                }}
              >
                {submitted ? (
                  <Spinner size="small" color={textColors.white} />
                ) : (
                  t("button.register")
                )}
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Content>
      <PageFooter />
    </Layout>
  );
};
