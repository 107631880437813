import {
  CheckSquareTwoTone,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { notification, Space, Table } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { categoryService } from "src/core/services/categoryService";
import { CategoryEditModal } from "src/modules/categoryEditModal/CategoryEditModal";
import { Category, FixedType, Menu } from "src/types/types";

type Props = {
  categories: Category[];
  onEditSuccess?: () => void;
  onPaginationChange: (size: number) => void;
  onPageChange: (page: number) => void;
  pageSize: number;
  currentPage: number;
  userMenus?: Menu[];
};

export const CategoriesTable = (props: Props) => {
  const [editCategory, setEditCategory] = useState<Category>();
  const [parentCategoryIds, setParentCategoryIds] = useState<number[]>([]);
  const [showEdit, setShowEdit] = useState(false);

  const {
    categories,
    onPaginationChange,
    pageSize,
    onPageChange,
    currentPage,
    userMenus,
  } = props;
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const ids = categories
        .filter((c) => (c.parentId || 0) > 0)
        .map((x) => x.parentId || -1);

      setParentCategoryIds(ids);
    })();
  }, [categories]);

  const forceCategoryDelete = async (categoryId: number, menuId: number) => {
    if (window.confirm(t("warning.deletionFailedForceDelete"))) {
      const deletionResult = await categoryService.deleteCategory(
        categoryId,
        menuId,
        true
      );

      if (deletionResult) {
        notification.success({
          message: t("label.success"),
          description: t("message.categoryDeleted"),
        });
      }

      // refresh categories
      if (props.onEditSuccess) {
        props.onEditSuccess();
      }
    }
  };

  const columns = [
    {
      title: t("table.column.title"),
      key: "title",
      render: (cat: Category) => {
        if ((cat.parentId || 0) > 0) {
          return (
            <span
              style={{
                marginLeft: 25,
                color: cat.hidden ? "gray" : "black",
                textDecoration: cat.hidden ? "line-through" : "none",
              }}
            >
              {cat.title}
            </span>
          );
        } else {
          return (
            <b
              style={{
                color: cat.hidden ? "gray" : "black",
                textDecoration: cat.hidden ? "line-through" : "none",
              }}
            >
              {cat.title}
            </b>
          );
        }
      },
      width: 200,
    },
    {
      title: t("table.column.description"),
      dataIndex: "description",
      key: "description",
      width: 200,
    },
    {
      title: t("table.column.orderNumber"),
      dataIndex: "orderNumber",
      key: "orderNumber",
      width: 80,
    },
    {
      title: t("table.column.twoColumns"),
      key: "twoColumns",
      width: 50,
      render: (item: Category) => {
        if (item.twoColumns) {
          return (
            <div style={{ textAlign: "center" }}>
              <CheckSquareTwoTone />
            </div>
          );
        } else {
          return "";
        }
      },
    },
    {
      title: t("table.column.sortByPrice"),
      key: "sortByPrice",
      width: 50,
      render: (item: Category) => {
        if (item.orderByPrice) {
          return (
            <div style={{ textAlign: "center" }}>
              <CheckSquareTwoTone />
            </div>
          );
        } else {
          return "";
        }
      },
    },
    {
      title: "",
      key: "action",
      render: (item: Category) => (
        <Space size="middle" wrap>
          <EditOutlined
            title={t("table.button.editCategory")}
            style={{ color: "#40a9ff", cursor: "pointer" }}
            onClick={() => {
              setEditCategory(item);
              setShowEdit(true);
            }}
          />

          {!parentCategoryIds.includes(item.id || 0) && (
            <DeleteOutlined
              title={t("table.button.delete")}
              style={{ color: "#40a9ff", cursor: "pointer" }}
              onClick={async () => {
                if (window.confirm(t("table.warning.categoryDelete"))) {
                  const deletionResult = await categoryService.deleteCategory(
                    item.id || 0,
                    item.menuId || 0
                  );

                  if (!deletionResult) {
                    await forceCategoryDelete(item.id || 0, item.menuId || 0);
                  } else {
                    notification.success({
                      message: t("label.success"),
                      description: t("message.categoryDeleted"),
                    });
                  }

                  // refresh categories
                  if (props.onEditSuccess) {
                    props.onEditSuccess();
                  }
                }
              }}
            />
          )}
        </Space>
      ),
      fixed: "right" as FixedType,
      width: 80,
    },
  ];

  const onSave = async () => {
    if (props.onEditSuccess) {
      props.onEditSuccess();
    }
  };

  return (
    <>
      <CategoryEditModal
        categoryProps={editCategory}
        activeState={[showEdit, setShowEdit]}
        onSuccess={onSave}
        userMenus={userMenus}
      />
      <Table
        key="cat-t"
        rowKey={({ id }) => `category-${id}`}
        scroll={{ x: 340 }}
        columns={columns}
        dataSource={categories}
        pagination={{
          hideOnSinglePage: false,
          defaultPageSize: pageSize,
          current: currentPage,
          showSizeChanger: true,
        }}
        onChange={(pagination) => {
          if (pagination.pageSize) {
            onPaginationChange(pagination.pageSize);
          }
          if (pagination.current) {
            onPageChange(pagination.current);
          }
        }}
      />
    </>
  );
};
