import { atom } from "recoil";
import { OrderItem } from "src/types/types";

type OrderCartData = {
  cartId: string;
  items: OrderItem[];
};

export const orderCartState = atom<OrderCartData>({
  key: "orderCart",
  default: {
    cartId: "0",
    items: [],
  },
});
