import {
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  FileAddOutlined,
  GlobalOutlined,
  QrcodeOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Image, Space, Table, Tooltip } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { menuDesignService } from "src/core/services/menuDesignService";
import { menuService } from "src/core/services/menuService";
import { multiMenuService } from "src/core/services/multiMenuService";
import { getPublicMenuLink, getQrCodeLink } from "src/core/utils/menuLink";
import { MultiMenuEditModal } from "src/modules/multiMenuEditModal/MultiMenuEditModal";
import { MultiMenuSectionCreateEditModal } from "src/modules/multiMenuSectionCreateModal/MultiMenuSectionCreateEditModal";
import {
  FixedType,
  Menu,
  MenuDesign,
  MultiMenu,
  MultiMenuSection,
} from "src/types/types";

type Props = {
  menus: MultiMenu[];
  onEditSuccess?: () => void;
};

export const MultiMenusTable = (props: Props) => {
  const [loading, setLoading] = useState(true);
  const [editMenu, setEditMenu] = useState<MultiMenu>();
  const [showEdit, setShowEdit] = useState(false);
  const [showAddSection, setShowAddSection] = useState(false);
  const [addSectionMenuId, setAddSectionMenuId] = useState(0);

  const [editMenuSection, setEditMenuSection] = useState<MultiMenuSection>();
  const [showEditMenuSection, setShowEditMenuSection] = useState(false);

  const [userMenus, setUserMenus] = useState<Menu[]>([]);
  const [userMultiMenus, setUserMultiMenus] = useState<MultiMenu[]>([]);
  const [sectionsLoading, setSectionsLoading] = useState(false);

  const [menuSections, setMenuSections] = useState<MultiMenuSection[][]>([]);
  const [menuDesigns, setMenuDesigns] = useState<MenuDesign[]>();

  const { menus } = props;
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const response = await menuService.fetchUserMenus();
      setUserMenus(response);
      const multiResponse = await multiMenuService.fetchUserMultiMenus();
      setUserMultiMenus(multiResponse);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const response = await menuDesignService.getMenuDesigns();
      setMenuDesigns(response);

      setLoading(false);
    })();
  }, []);

  const reloadMenuSections = async (menuId: number) => {
    setSectionsLoading(true);
    const items = await multiMenuService.fetchMultiMenuSections(menuId);

    items.map((section: MultiMenuSection) => {
      let itemMenu = userMenus.find(
        (c) => c.identifier === section.menuIdentifier
      );

      if (itemMenu) {
        section.menuTitle = itemMenu?.description
          ? `${itemMenu?.title} (${itemMenu?.description?.substring(0, 100)})`
          : itemMenu?.title;
        section.menuIdentifier = itemMenu?.identifier;
      } else {
        itemMenu = userMultiMenus.find(
          (c) => c.identifier === section.menuIdentifier
        );

        section.menuTitle = itemMenu?.title;
        section.menuIdentifier = itemMenu?.identifier;
      }

      return section;
    });

    menuSections[menuId] = items;

    setMenuSections(menuSections);
    setSectionsLoading(false);
  };

  const onExpand = async (expanded: boolean, item: MultiMenu) => {
    if (expanded) {
      await reloadMenuSections(item.id || 0);
    }
  };

  const expandedRowRender = (expandedMenu: MultiMenu) => {
    const columns = [
      {
        title: t("table.column.header"),
        key: "title",
        width: 200,
        render: (section: MultiMenuSection) =>
          section.hidden ? (
            <b style={{ color: "gray", textDecoration: "line-through" }}>
              {section.title}
            </b>
          ) : (
            <b>{section.title}</b>
          ),
      },
      {
        title: t("table.column.description"),
        dataIndex: "description",
        key: "description",
        width: 100,
      },
      {
        title: t("table.column.menu"),
        dataIndex: "menuTitle",
        key: "menuTitle",
      },
      {
        title: "",
        key: "operation",
        width: 100,
        render: (item: MultiMenuSection) => (
          <Space size={[8, 16]} wrap align="center">
            <EditOutlined
              title={t("table.button.editMenuSection")}
              style={{ color: "#40a9ff", cursor: "pointer" }}
              onClick={() => {
                setAddSectionMenuId(item.multiMenuId || 0);
                setEditMenuSection(item);
                setShowEditMenuSection(true);
              }}
            />
            <a
              href={getPublicMenuLink(item?.menuIdentifier)}
              target="_blank"
              rel="noreferrer"
            >
              <GlobalOutlined title="Peržiūrėti" />
            </a>
            <DeleteOutlined
              title={t("table.button.delete")}
              style={{ color: "#40a9ff", cursor: "pointer" }}
              onClick={async () => {
                if (window.confirm(t("table.warning.menuSectionDelete"))) {
                  await multiMenuService.deleteMultiMenuSection(item);

                  await reloadMenuSections(item.multiMenuId || 0);
                }
              }}
            />
          </Space>
        ),
      },
    ];

    return (
      <Table
        scroll={{ x: 200 }}
        columns={columns}
        dataSource={menuSections[expandedMenu.id || "0"]}
        pagination={false}
        loading={sectionsLoading}
      />
    );
  };

  const columns = [
    {
      title: t("table.column.title"),
      key: "title",
      render: (menu: Menu) => {
        if (menu.headerLogo && menu.headerLogo !== "") {
          return (
            <Space>
              <Image
                src={menu.headerLogo}
                style={{ maxHeight: 40 }}
                preview={false}
              />{" "}
              <b>{menu.title}</b>
            </Space>
          );
        }
        return <b>{menu.title}</b>;
      },
      width: 150,
    },
    {
      title: t("table.column.description"),
      dataIndex: "description",
      key: "description",
      width: 200,
    },
    {
      title: t("table.column.design"),
      dataIndex: "menuDesignId",
      key: "menuDesignId",
      render: (designId: number) => {
        const label = menuDesigns?.find((design) => design.id === designId);

        return label?.title;
      },
      width: 100,
    },
    {
      title: "",
      key: "action",
      render: (item: MultiMenu) => (
        <Space size={[8, 16]} wrap align="center">
          <EditOutlined
            title={t("table.button.edit")}
            style={{ color: "#40a9ff", cursor: "pointer" }}
            onClick={() => {
              setEditMenu(item);
              setShowEdit(true);
            }}
          />
          <Tooltip
            placement="top"
            defaultOpen={moment().diff(moment(item.created_at), "hours") < 1}
            title={t("table.button.assignMenu")}
          >
            <FileAddOutlined
              title={t("table.button.assignMenu")}
              style={{ color: "#40a9ff", cursor: "pointer" }}
              onClick={() => {
                setAddSectionMenuId(item.id || 0);
                setShowAddSection(true);
              }}
            />
          </Tooltip>

          <DeleteOutlined
            title={t("table.button.delete")}
            style={{ color: "#40a9ff", cursor: "pointer" }}
            onClick={async () => {
              if (window.confirm(t("table.warning.menuSectionDelete"))) {
                await multiMenuService.deleteMultiMenu(item.id || 0);

                // refrech menus
                if (props.onEditSuccess) {
                  props.onEditSuccess();
                }
              }
            }}
          />
          <a
            href={getQrCodeLink(item.identifier)}
            target="_blank"
            rel="noreferrer"
          >
            <QrcodeOutlined title={t("table.button.qrCode")} />
          </a>
          <a
            href={getPublicMenuLink(item.identifier)}
            target="_blank"
            rel="noreferrer"
          >
            <GlobalOutlined title={t("table.button.view")} />
          </a>
        </Space>
      ),
      fixed: "right" as FixedType,
      width: 90,
    },
  ];

  const onSave = async () => {
    if (props.onEditSuccess) {
      props.onEditSuccess();
    }
  };

  const onSectionAdded = async () => {
    setShowAddSection(false);
    await reloadMenuSections(addSectionMenuId);
  };

  const onSectionUpdated = async () => {
    setShowEditMenuSection(false);
    await reloadMenuSections(addSectionMenuId);
  };

  return (
    <>
      <MultiMenuEditModal
        menuProps={editMenu}
        activeState={[showEdit, setShowEdit]}
        onSuccess={onSave}
      />
      <MultiMenuSectionCreateEditModal
        key={"m-s-create-modal"}
        activeState={[showAddSection, setShowAddSection]}
        multiMenuId={addSectionMenuId}
        onSuccess={onSectionAdded}
        editMode={false}
      />
      <MultiMenuSectionCreateEditModal
        key={"m-s-edit-modal"}
        activeState={[showEditMenuSection, setShowEditMenuSection]}
        multiMenuId={addSectionMenuId}
        onSuccess={onSectionUpdated}
        editMode={true}
        id={editMenuSection?.id}
        title={editMenuSection?.title}
        description={editMenuSection?.description}
        menuIdentifier={editMenuSection?.menuIdentifier}
        hidden={editMenuSection?.hidden}
      />
      <Table
        loading={loading}
        scroll={{ x: 360 }}
        columns={columns}
        dataSource={menus}
        rowKey={(record: MultiMenu) => {
          return (record.id || "") as string;
        }}
        pagination={{ hideOnSinglePage: true }}
        expandable={{
          expandedRowRender,
          onExpand,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <DownOutlined onClick={(e) => onExpand(record, e)} />
            ) : (
              <RightOutlined onClick={(e) => onExpand(record, e)} />
            ),
        }}
      />
    </>
  );
};
