import { getAccessToken } from "src/authProvider";

async function http<T>(request: RequestInfo): Promise<T> {
  try {
    const response = await fetch(request);

    const body = await response.json();

    if (body.message && body.message === "Unauthenticated.") {
      window.location.replace("/");
    }

    return body.data as T;
  } catch (error) {
    console.error(error);
    return {} as T;
  }
}

async function httpVoid(request: RequestInfo): Promise<void> {
  try {
    const response = await fetch(request);

    if (!response.ok) {
      const body = await response.json();

      if (body && body.message && body.message === "Unauthenticated.") {
        window.location.replace("/");
      }
    }
  } catch (error) {
    throw error;
  }
}

export async function get<T>(
  path: string,
  args: RequestInit = {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }
): Promise<T> {
  return await http<T>(new Request(path, args));
}

export async function getUnauthorized<T>(
  path: string,
  args: RequestInit = {
    method: "get",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }
): Promise<T> {
  return await http<T>(new Request(path, args));
}

export async function postUnauthorized<T>(
  path: string,
  body: any,
  args: RequestInit = {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  }
): Promise<T> {
  return await http<T>(new Request(path, args));
}

export async function deleteUnauthorized(
  path: string,
  body: any,
  args: RequestInit = {
    method: "delete",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: body ? null : JSON.stringify(body),
  }
): Promise<void> {
  return await httpVoid(new Request(path, args));
}

export async function post<T>(
  path: string,
  body: any,
  args: RequestInit = {
    method: "post",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
    body: JSON.stringify(body),
  }
): Promise<T> {
  return await http<T>(new Request(path, args));
}

export async function put<T>(
  path: string,
  body: any,
  args: RequestInit = {
    method: "put",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
    body: JSON.stringify(body),
  }
): Promise<T> {
  return await http<T>(new Request(path, args));
}

export async function destroy<T>(
  path: string,
  args: RequestInit = {
    method: "delete",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`,
    },
  }
) {
  return await http<T>(new Request(path, args));
}
